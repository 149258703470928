import { Colors } from 'common';
import styled, { createGlobalStyle } from 'styled-components';

import Mask from 'img/title/ThumbnailMask.svg';
import { vw } from 'common/utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  white-space: pre-line;

  padding-left: ${vw(53)}px;

  height: ${vw(600)}px;

  position: relative;

  background-color: ${Colors.gold200};

  overflow: hidden;
`;

export const ImageContainer = styled.img`
  position: absolute;

  bottom: ${vw(213)}px;
  right: ${vw(20)}px;

  -webkit-mask-image: url(${Mask});
  mask-image: url(${Mask});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  object-fit: contain;
`;
