import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomBalloonImage from 'img/photos/disease/0/SymptomBalloon.svg';
import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';
import BackgroundImage from 'img/photos/aracare/4/background.png';
import CauseContainerImage from 'img/photos/disease/4/cause_container.png';

import Content1BackgroundImage from 'img/photos/aracare/4/background_1.png';
import { vw } from 'common/utils';

export const StepContainer = styled.div`
  width: ${vw(103)}px;
  height: ${vw(117)}px;

  border-radius: ${vw(10)}px;
  border: 1px solid var(--ebe-2-cc, #ebe2cc);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepIconContainer = styled.div`
  width: ${vw(46)}px;
  height: ${vw(29)}px;

  border-radius: ${vw(4)}px;
  background: var(--ebe-2-cc, #ebe2cc);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PointContainer = styled.div`
  width: ${vw(115)}px;
  height: ${vw(115)}px;

  border-radius: ${vw(10)}px;
  background: #fff;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PointIndexContainer = styled.div`
  width: ${vw(60)}px;
  height: ${vw(60)}px;

  border-radius: 50%;

  background: var(--665-e-4-b, #665e4b);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: ${vw(-25)}px;
  top: ${vw(-30)}px;

  z-index: 1;
`;
