import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './checkup2.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import Banner from 'img/photos/checkup/2/banner.png';

import SymptomImage0 from 'img/photos/disease/10/symptomImage/0.png';
import SymptomImage1 from 'img/photos/disease/10/symptomImage/1.png';
import SymptomImage2 from 'img/photos/disease/10/symptomImage/2.png';
import SymptomImage3 from 'img/photos/disease/10/symptomImage/3.png';
import SymptomImage4 from 'img/photos/disease/10/symptomImage/4.png';

const SymptomDatas = [
  {
    title: '안면홍조',
    content: '얼굴이 화끈거리며 빨갛게 달아오르고\n땀을 비오듯 흘리기도 합니다.',
    image: SymptomImage0,
  },
  {
    title: '정신적 불안정',
    content: '우울증, 정서불안, 신경과민,\n기억력 감소 등의 증상이 나타납니다.',
    image: SymptomImage1,
  },
  {
    title: '수면장애',
    content: '밤에 충분히 수면을 취하지 못하고\n만성피로, 두통을 유발합니다.',
    image: SymptomImage2,
  },
  {
    title: '피부 노화',
    content: '에스트로겐 분비가 감소해 피부 탄력이\n떨어지고 주름이 증가합니다.',
    image: SymptomImage3,
  },
  {
    title: '골다공증',
    content: '에스트로겐이 감소함에 따라 골흡수와 형성에\n불균형을 초래해 골다공증이 발생합니다.',
    image: SymptomImage4,
  },
];

const CheckUpTypeDatas = ['호르몬 검사', '초음파 검사', '콜레스테롤 검사', '간기능 검사', 'Vit D 검사', '자궁경부암 검사'];

import ProgramImage0 from 'img/photos/checkup/0/programImages/0.jpg';
import ProgramImage1 from 'img/photos/checkup/0/programImages/1.jpg';
import ProgramImage2 from 'img/photos/checkup/0/programImages/2.png';

const ProgramStepDatas = [
  {
    title: '검사 전 상담',
    content:
      '가족력 조사와 각종 질병에 대한 상담과 불임,\n질염과 성병, 약물, 비만, 당뇨, 갑상선 질환 등등\n임신에 문제가 되거나, 임신 후 태아에게\n악영향을 줄 수 있는 문제에 대한 상담과\n평가를 먼저합니다.',
    image: ProgramImage0,
  },
  {
    title: '검사',
    content: '이러한 자료를 바탕으로 환자분 개개인에\n맞추어 필요한 예비부부 검사 프로그램을\n만들어 제시 해드립니다. ',
    image: ProgramImage1,
  },
  {
    title: '검사 후 결과상담',
    content:
      '가족력 조사와 각종 질병에 대한 상담과 불임,\n질염과 성병, 약물, 비만, 당뇨, 갑상선 질환 등등\n임신에 문제가 되거나, 임신 후 태아에게\n악영향을 줄 수 있는 문제에 대한 상담과\n평가를 먼저합니다.',
    image: ProgramImage2,
  },
];

import StepBarImage from 'img/photos/checkup/0/bar.png';

function CheckUp2(): ReactElement {
  const menuIndex = 3;
  const subIndex = 2;

  return (
    <>
      <Header />

      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />

          <Common.SizedImage src={Banner} width={334} height={264} objectFit="cover" />
          <Common.TitleDescription>
            갱년기 증상이 있으면서 3개월 이상 생리가 없으면 폐경 유무를 호르몬 검사를 통하여 확인 합니다.
            <br />
            호르몬 요법을 원하는 경우, 폐경 진단을 확인 하는 경우 등은 혈액 검사가 필요합니다.{' '}
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            갱년기 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          {SymptomDatas.map((e, i) => (
            <>
              <Components.Common.IndexLineContent data={{ ...e, index: i }} />
              {i < SymptomDatas.length - 1 && <Common.SizedBoxH height={20} />}
            </>
          ))}

          <Common.SizedBoxH height={60} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Test
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            검사항목
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={20} />

          <Styled.TestTypeContents>
            {CheckUpTypeDatas.map((e) => (
              <Typo.Pretendard_Regular textAlign="left" color={Colors.gray200} fontSize={13}>
                · {e}
              </Typo.Pretendard_Regular>
            ))}
          </Styled.TestTypeContents>

          <Common.SizedBoxH height={40} />
        </Common.Contents>
      </Common.Container>

      <Footer />
    </>
  );
}

export default CheckUp2;
