import styled from 'styled-components';
import { Colors } from 'common';
import * as Typo from 'common/typography';

import { vw } from 'common/utils';

export const TitleContainer = styled.div`
  width: 100%;
  height: ${vw(241)}px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${Colors.white};
  background: linear-gradient(180deg, #ebe2cc 0%, #f1e4c4 29.69%, rgba(235, 226, 204, 0.47) 100%);

  background-size: 100% ${vw(398)}px;
`;

export const StoryContainer = styled.div`
  width: ${vw(334)}px;
  height: ${vw(310)}px;

  position: relative;

  border-radius: 0 ${vw(20)}px ${vw(20)}px ${vw(20)}px;
  background-color: ${Colors.white};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const StoryIconContainer = styled.div`
  width: ${vw(334)}px;
  height: ${vw(200)}px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${Colors.gold200};
  overflow: hidden;

  border-radius: 0 ${vw(20)}px 0 0;
`;

export const StoryTextContainer = styled.div`
  width: ${vw(334)}px;

  display: flex;
  flex-direction: column;

  padding: ${vw(17)}px 0 0 ${vw(20)}px;
`;

export const StoryIndexContainer = styled.div`
  width: ${vw(93)}px;
  height: ${vw(93)}px;

  border-radius: 50%;

  background: var(--665-e-4-b, #665e4b);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: ${vw(-49)}px;

  z-index: 1;
`;

export const StoryTitleText = styled(Typo.Pretendard_Medium)`
  font-size: ${vw(20)}px;
  text-align: left;
  color: ${Colors.gray300};

  mark {
    color: ${Colors.gold300};
    background-color: transparent;
  }
`;

export const WhyCard = styled.div`
  width: ${vw(334)}px;
  height: ${vw(190)}px;
  background: #ebe2cc;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WhyCardViewport = styled.div`
  width: ${vw(256)}px;
  height: ${vw(146)}px;
  border-radius: 0px 0px 0px ${vw(100)}px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 ${vw(14)}px;
`;
