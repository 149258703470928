import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './aracare0.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';

import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import IconShadow from 'img/photos/aracare/0/shadow.png';

import Icon0 from 'img/photos/aracare/0/icons/0.png';
import Icon1 from 'img/photos/aracare/0/icons/1.png';
import Icon2 from 'img/photos/aracare/0/icons/2.png';
import Icon3 from 'img/photos/aracare/0/icons/3.png';
import Icon4 from 'img/photos/aracare/0/icons/4.png';
import Icon5 from 'img/photos/aracare/0/icons/5.png';
import Icon6 from 'img/photos/aracare/0/icons/6.png';
import Icon7 from 'img/photos/aracare/0/icons/7.png';
import Icon8 from 'img/photos/aracare/0/icons/8.png';
import Icon9 from 'img/photos/aracare/0/icons/9.png';
import Icon10 from 'img/photos/aracare/0/icons/10.png';
import Icon11 from 'img/photos/aracare/0/icons/11.png';

interface SolutionData {
  title: string;
  content: string;
  icon: '*.png';
}

const Solution1Datas: SolutionData[] = [
  {
    title: '고영양주사',
    content:
      '종합영양소를 보충해주는 영양주사입니다.\n음식 또는 영양제로 섭취하는 영양소를 한 번에 공급해 만성피로 회복, 면역력 증강 등 전반적인 신체 건강 개선에 도움을 줍니다.',
    icon: Icon0,
  },
  {
    title: '면역력주사',
    content:
      '우리 몸에 존재하는 NKcell(자연살해세포)를 활성화해 감염된 세포 및 노화세포 제거하고,\n그 외 모든 면역세포의 증식 및 활성화해 면역력을 증가시킵니다.',
    icon: Icon1,
  },
  {
    title: '감초주사',
    content:
      '한약재 감초의 뿌리에서 유래한 성분으로 원활한 신진대사를 도와 면역력 및 체력 증가에 도움을 줍니다.\n해독 효과, 항알레르기 작용, 피부 탄력 증가에도 효과가 있습니다.',
    icon: Icon2,
  },
];

const Solution2Datas: SolutionData[] = [
  {
    title: '피로회복주사',
    content: '비타민 복합 제재를 이용하여 기력회복, 만성 피로 회복에 도움을 줍니다.',
    icon: Icon3,
  },
  {
    title: '에너지주사',
    content:
      'ATP를 혈관으로 직접 주입하여 생체내 대사활성을 증가시켜 피로회복에 효과가 있습니다. 혈류개선, 수술 후 기능회복, 만성통증개선, 간기능개선에도 효과를 보입니다.',
    icon: Icon4,
  },
];

const Solution3Datas: SolutionData[] = [
  {
    title: '백옥주사',
    content:
      '글루타치온 성분이 멜라닌 색소의 활성을 억제하여 미백효과를 줍니다.\n 맑고 하얀 피부를 원할 때, 과격한 운동을 즐겨할 때, 잦은 음주나 흡연 시, 간손상으로 피로를 느낄 때 도움이 됩니다.',
    icon: Icon5,
  },
  {
    title: '신데렐라주사',
    content: '알파리포산 성분이 작용을 하여 피부노화 개선, 탄력증가, 피로회복, 체지방 감소(특히 내장비만)에 도움을 줍니다.',
    icon: Icon6,
  },
];

const SolutionData4: SolutionData = {
  title: '라이넥주사',
  content:
    '미네랄, 콜라겐, 아미노산, 효소 등을 공급받아 만성피로, 우울감, 불면증 등의 갱년기 증상을 개선합니다.\n이외에도 생리불순 및 생리통 개선, 항노화, 피부미용 및\n피로회복, 활성 산소제거, 해독, 간기능 개선에도 도움을 줍니다.',
  icon: Icon7,
};

const SolutionData5: SolutionData = {
  title: '비만주사',
  content:
    '지방분해효소와 에너지 대사 인자를 촉진시켜 지방분해에 효과가 있습니다. 체지방 감소, 기초대사량증가, 근육량 증가 및 노화방지에 도움을 주며, 피로회복과 면역력 강화, 혈액순환 강화에도 효과가 있습니다.',
  icon: Icon8,
};

const SolutionData6: SolutionData = {
  title: '숙취해소주사',
  content:
    '간해독, 붓기제거, 피부 보습 및 탄력에 효과 있는 성분을 이용하여 피로해진 몸을 해독하고 항암, 항염 효과를 나타냅니다.\n몸속의 영양소를 보충해서 신진대사를 활발하게 도와주며, 활력강화에 도움을 줍니다.',
  icon: Icon9,
};

const SolutionData7: SolutionData = {
  title: '비타민D주사',
  content:
    '야외활동이 부족한 현대인을 위한 필수 주사입니다.\nVITD 부족에 의한 면역력 저하, 골밀도 저하, 만성통증, 우울감, 수면부족, 아토피 등의 각종 피부질환 등 해결에 도움을 줍니다.',
  icon: Icon10,
};

const SolutionData8: SolutionData = {
  title: '항산화주사',
  content:
    '미량원소(아연, 구리, 망간, 셀레늄, 크롬등)의 공급을 통해 신체기 회복, 항산화 및 노화방지, 상처회복, 피로회복에 도움을 줍니다.\n특히 셀레늄의 경우 비타민 E보다 1,700배의 항산화 능력이 있어 노화를 촉진하는 물질의 생성을 방치하고, DNA의 손상을 막아 노화진행을 늦춰줍니다.\n또한 암세포를 죽이는 작용을 해 항암 효과도 나타냅니다.',
  icon: Icon11,
};

function AraCare0(): ReactElement {
  const menuIndex = 4;
  const subIndex = 0;

  return (
    <>
      <Header />

      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Solution 01
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            기력이 없거나
            <br />
            면역력이 떨어졌을 때
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={60} />

          {Solution1Datas.map((e, i) => (
            <>
              <Components.Common.IndexCard
                data={{ ...e, indexString: 'Solution', index: i, image: e.icon, imageWidth: 100, imageHeight: 100 }}
              />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Solution 02
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            지속되는 만성피로
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={60} />

          {Solution2Datas.map((e, i) => (
            <>
              <Components.Common.IndexCard
                data={{ ...e, indexString: 'Solution', index: i, image: e.icon, imageWidth: 100, imageHeight: 100 }}
              />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Solution 03
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            피부가 맑아지는
            <br />
            미백을 원할 때
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={60} />

          {Solution3Datas.map((e, i) => (
            <>
              <Components.Common.IndexCard
                data={{ ...e, indexString: 'Solution', index: i, image: e.icon, imageWidth: 100, imageHeight: 100 }}
              />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Solution 04
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            갱년기 여성들을 위한
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={60} />

          <Components.Common.IndexCard
            data={{ ...SolutionData4, indexString: 'Solution', index: 0, image: SolutionData4.icon, imageWidth: 100, imageHeight: 100 }}
          />
          <Common.SizedBoxH height={60} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Solution 05
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            군살 없는 몸매를 원한다면
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={60} />

          <Components.Common.IndexCard
            data={{ ...SolutionData5, indexString: 'Solution', index: 0, image: SolutionData5.icon, imageWidth: 100, imageHeight: 100 }}
          />
          <Common.SizedBoxH height={60} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Solution 06
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            숙취로 힘들다면
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={60} />

          <Components.Common.IndexCard
            data={{ ...SolutionData6, indexString: 'Solution', index: 0, image: SolutionData6.icon, imageWidth: 100, imageHeight: 100 }}
          />
          <Common.SizedBoxH height={60} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Solution 07
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            야외활동이 부족한
            <br />
            사람이라면
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={60} />

          <Components.Common.IndexCard
            data={{ ...SolutionData7, indexString: 'Solution', index: 0, image: SolutionData7.icon, imageWidth: 100, imageHeight: 100 }}
          />
          <Common.SizedBoxH height={60} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Solution 08
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            노화방지 or 수술 후
            <br />
            상처회복이 필요할 때
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={60} />

          <Components.Common.IndexCard
            data={{ ...SolutionData8, indexString: 'Solution', index: 0, image: SolutionData8.icon, imageWidth: 100, imageHeight: 100 }}
          />
          <Common.SizedBoxH height={60} />
        </Common.Contents>
      </Common.Container>

      <Footer />
    </>
  );
}

export default AraCare0;
