import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';

import styled from 'styled-components';
import { Colors } from 'common';
import { vw } from 'common/utils';

import ContainerImage from 'img/common/paperListContainer.png';
import CheckImage from 'img/common/PaperListCheck.png';

const Container = styled.div`
  width: ${vw(343)}px;
  min-height: ${vw(150)}px;

  background-image: url(${ContainerImage});
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  transform: translateX(${vw(8)}px);

  padding: ${vw(34)}px ${vw(25)}px ${vw(57)}px ${vw(40)}px;
`;

const CheckContainer = styled.div`
  width: ${vw(10)}px;
  height: ${vw(10)}px;
  border: ${vw(2)}px solid var(--665-e-4-b, #665e4b);

  position: relative;
`;

const CheckImageContainer = styled.img`
  width: ${vw(14)}px;
  height: ${vw(14)}px;
  position: absolute;
  left: ${vw(-4)}px;
  bottom: ${vw(2)}px;
`;

const Dash = styled.div`
  width: ${vw(249)}px;
  height: 0px;
  border: ${vw(0.5)}px dashed ${Colors.black};
`;

function PaperCheckList(props: { data: string[] }): ReactElement {
  const { data } = props;
  return (
    <Container>
      {data.map((e, i) => (
        <>
          <Common.FlexRow alignItems="center">
            <CheckContainer>
              <CheckImageContainer src={CheckImage} />
            </CheckContainer>
            <Common.SizedBoxW width={12} />
            <Typo.Pretendard_Regular fontSize={12} color={Colors.gray200} textAlign="left">
              {e}
            </Typo.Pretendard_Regular>
          </Common.FlexRow>

          <Common.SizedBoxH height={3} />

          <Dash />

          {i < data.length - 1 && <Common.SizedBoxH height={8} />}
        </>
      ))}
    </Container>
  );
}

export default PaperCheckList;
