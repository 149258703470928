import Logo from 'img/common/header_logo_new.png';

export const CommonImages = {
  Logo,
};

// icons
import ArrowLeft from 'img/icons/ArrowLeft.svg';
import ArrowRight from 'img/icons/ArrowRight.svg';
import Clock from 'img/icons/Clock.svg';
import Ping from 'img/icons/Location.svg';
import Phone from 'img/icons/Phone.svg';
import Menu from 'img/icons/Menu.svg';
import Close from 'img/icons/Close.svg';

export const Icons = {
  ArrowLeft,
  ArrowRight,
  Clock,
  Ping,
  Phone,
  Menu,
  Close,
};
