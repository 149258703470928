import styled from 'styled-components';
import { Colors } from 'common';
import * as Typo from 'common/typography';

import { vw } from 'common/utils';

export const TitleContainer = styled.div`
  width: 100%;
  height: ${vw(241)}px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${Colors.white};
  background: linear-gradient(180deg, #ebe2cc 0%, #f1e4c4 29.69%, rgba(235, 226, 204, 0.47) 100%);

  background-size: 100% ${vw(398)}px;
`;

export const PreviewDiv = styled.div`
  width: ${vw(334)}px;
  height: ${vw(330)}px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PreviewPrevBtn = styled.button`
  position: absolute;
  width: ${vw(24)}px;
  height: ${vw(46)}px;

  left: ${vw(5)}px;
  top: ${vw(132)}px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
  padding: 0;
`;

export const PreviewNextBtn = styled.button`
  position: absolute;
  width: ${vw(24)}px;
  height: ${vw(46)}px;

  right: ${vw(5)}px;
  top: ${vw(132)}px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
  padding: 0;
`;

export const PreviewName = styled.div`
  width: 100%;
  height: ${vw(40)}px;

  position: absolute;
  bottom: 0;
  background-color: rgba(256, 256, 256, 0.7);

  padding: ${vw(20)}px;
  display: flex;
  align-items: center;

  font-family: Pretendard;
  font-weight: 500;
  font-size: ${vw(20)}px;
  color: ${Colors.black};
`;

export const PhotoBtn = styled.button`
  width: ${vw(75)}px;
  height: ${vw(40)}px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  background: none;
  border: none;

  opacity: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  :disabled {
    opacity: 0.5;
  }
`;
