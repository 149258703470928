import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import Banner from 'img/photos/aracare/2/background.png';

import Icon0 from 'img/photos/aracare/2/solution_0.jpg';
import Icon1 from 'img/photos/aracare/2/solution_1.jpg';

import OKImage from 'img/photos/aracare/2/Ok.png';

const Solution1Datas = [
  {
    title: '삭센다',
    content:
      '삭센다는 ‘라라글루티드’라는 성분의 비만 치료 주사입니다.\n뇌에서는 식욕 억제를, 위에서는 위장 운동을 저하시켜 포만감을 증가\n시킵니다. 하루에 한 번 자가 투여하는 방식으로 간편하게 체중 관리를\n할 수 있습니다.',
    image: Icon0,
  },
  {
    title: '큐시미아',
    content: '큐시미아는 펜터민, 토피라메이트 성분으로 구성된 비만약입니다.\n뇌가 배고픔을 느끼지 못하게 만들어 체중 감량을 돕습니다.',
    image: Icon1,
  },
];

import DownArrow from 'img/photos/disease/DownArrow.png';
import BackgroundImage from 'img/photos/aracare/2/background.png';

import CheckImage from 'img/photos/disease/4/check.png';

const RecommandDatas = [
  '혼자의 힘으로 다이어트를 하는 것이 힘드신분',
  '운동과 식단 관리로 빠지지 않는 군살이 고민이신 분',
  '복부, 허벅지 등 특정 부위에 살이 집중되어 있는 분',
  '출산 후 혹은 갱년기에 급격하게 체중이 늘어난 분',
];

function AraCare2(): ReactElement {
  // 임시
  // const menuIndex = 4;
  // const subIndex = 2;
  const menuIndex = 3;
  const subIndex = 1;
  return (
    <>
      <Header />

      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />

          <Common.SizedImage src={Banner} width={334} height={264} objectFit="cover" />
          <Common.TitleDescription>
            현대인들이 평생 달고 살아야 하는 숙제와도 같습니다.
            <br />
            적정 체중을 넘어선 채로 장기간 유지하다 보면 고지혈증, 당뇨병 등 다양한 질환을 초래할 가능성이 매우 높아지기 때문에 관리가
            필요합니다.
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Solution
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            호르몬 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {Solution1Datas.map((e, i) => (
            <>
              <Components.Common.IndexCard data={{ ...e, index: i, indexString: 'Solution' }} />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>

        <Common.Contents backgroundImage={BackgroundImage}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Point
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            아라산부인과
            <br />
            비만 클리닉 key point
          </Typo.Pretendard_Medium>
          <Common.SizedImage src={DownArrow} width={60} height={60} />
          <Common.SizedBoxH height={9} />
          <Typo.Pretendard_Regular fontSize={15} lineHeight={25} color={Colors.gray300}>
            여성 비만의 원인은 식습관 및 생활습관의 문제 뿐만 아니라, 여성 질환이 원인일 경우가 많습니다.
            <br />
            따라서 단순히 체중 감량만을 목표로 두는 것이 아니라
            <br />
            질환 발생하였을 가능성을 열어 두고 꼼꼼한 진단을 하는 것이 중요합니다.
            <br />
            <br />
            아라산부인과는 산부인과 전문의의 진단을 통해 비만의 원인을 파악하고,
            <br />
            생리불순, 부정출혈 등 체중감량으로 인해 발생할 수 있는 각종 부작용을 예방합니다.
          </Typo.Pretendard_Regular>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Who
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            비만 클리닉 추천 대상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={10} />

          <Typo.Pretendard_Regular fontSize={14} color={Colors.gray200}>
            이런 분들에게 추천을 드립니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={20} />

          <Components.Common.PaperCheckList data={RecommandDatas} />

          <Common.SizedBoxH height={40} />
        </Common.Contents>
      </Common.Container>

      <Footer />
    </>
  );
}

export default AraCare2;
