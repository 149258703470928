import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';
import { vw } from 'common/utils';

export const Content1Title = styled.div`
  border: 1px solid var(--665-e-4-b, #665e4b);
  width: ${vw(180)}px;
  height: ${vw(40)}px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
