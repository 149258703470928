import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/9/banner.png';

import CheckImage from 'img/photos/disease/4/check.png';

const CauseDatas = [
  '반복된 임신과 출산으로 인한 경우',
  '노화로 방광을 지지하는 근육이 늘어진 경우',
  '당뇨, 중풍 등 만성 질환 혹은 골반부위 수술로 인한 경우',
  '방광염, 요도염으로 인한 경우',
];

import TypeImage0 from 'img/photos/disease/9/type_0.png';
import TypeImage1 from 'img/photos/disease/9/type_1.png';
import TypeImage2 from 'img/photos/disease/9/type_2.png';
import TypeImage3 from 'img/photos/disease/9/type_3.png';

const TypeDatas = [
  {
    title: '복압성 요실금',
    content: '가장 흔한 요실금의 종류로, 기침과 재채기 등\n배에 힘이 가해지는 사소한 행동으로도 소변이 새어 나오는 증상이 생깁니다.',
    image: TypeImage0,
    imageWidth: 100,
    imageHeight: 180,
  },
  {
    title: '절박성 요실금',
    content: '방광 근육이 불안정하여 발생하는 요실금으로,\n갑자기 강하게 소변이 마려운 증상이 생깁니다.',
    image: TypeImage1,
    imageWidth: 120,
    imageHeight: 180,
  },
  {
    title: '복합성 요실금',
    content: '고령의 여성에게서 발생률이 높은 요실금으로,\n복압성 요실금과 절박성 요실금이 혼합된 형태입니다.',
    image: TypeImage2,
    imageWidth: 180,
    imageHeight: 180,
  },
  {
    title: '일류성 요실금',
    content:
      '방광 신경 기능이 약해지거나 만성적인 약물 복용 또는\n평소에 소변을 오래 참는 습관으로 인해 방광 수축력이 떨어져 발생하는 요실금입니다.',
    image: TypeImage3,
    imageWidth: 180,
    imageHeight: 180,
  },
];

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '비수술적 치료',
    description: '약물 치료, 전기 자극 치료 등',
    image: TreatmentImage0,
  },
  {
    title: '수술적 치료',
    description: '요실금을 막아주는 구조물 삽입',
    image: TreatmentImage1,
  },
];

function Disease9(): ReactElement {
  const menuIndex = 2;
  const subIndex = 9;

  return (
    <>
      <Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />

          <Common.SizedImage src={BannerImage} width={334} height={264} objectFit="cover" />
          <Common.TitleDescription>
            본인의 의지와 상관없이 자신도 모르게 소변이 새는 현상을 말합니다. 우리나라 여성의 40%가 요실금 증상을 경험한다고 알려져 있으며,
            기침과 재채기를 하면 소변이 새어 나와 일상에서의 큰 불편을 호소합니다.{' '}
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            요실금 원인
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Components.Common.PaperCheckList data={CauseDatas} />

          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Type
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            요실금의 종류
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          {TypeDatas.map((e, i) => (
            <>
              <Components.Common.IndexLineContent data={{ ...e, index: i, imageObjectFit: 'contain' }} />
              {i < TypeDatas.length - 1 && <Common.SizedBoxH height={50} />}
            </>
          ))}

          <Common.SizedBoxH height={40} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Self - Diagnosis
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            요실금 자가진단
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Components.Common.IndexLineContent
            data={{
              index: 0,
              content:
                '요실금은 생명에 위험을 초래하는 질환은 아니지만 일상생활에서 큰 불편을 초래합니다. 아래와 같은 증상으로 인해 불편을 겪고 있다면 산부인과를 방문하는 것을 권합니다.',
            }}
          />
          <Components.Common.Symptoms
            data={[
              '기침이나 재채기를 하면 나도 모르게 소변이 새어 나와 속옷을 적신 적이 있다.',
              '밤에 잠을 자다가 소변이 마려워 자주 깬다.',
              '소변을 봐도 시원하지 않고 소변 줄기에 힘이 없다.',
              '갑자기 소변이 마렵기 시작하면 참을 수가 없다.',
            ]}
          />

          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            요실금 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {TreatmentDatas.map((e, i) => (
            <>
              <Components.Common.IndexCard data={{ ...e, index: i, indexString: 'Method', content: e.description }} />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Disease9;
