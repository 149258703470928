import { Colors } from 'common';
import { vw } from 'common/utils';
import styled, { createGlobalStyle } from 'styled-components';

export const RoundedCard = styled.div`
  width: ${vw(275)}px;
  height: ${vw(180)}px;

  border-radius: 0px ${vw(100)}px 0px 0px;
  background: #f8f8f8;

  padding: ${vw(31)}px 0 0 ${vw(19)}px;
`;
