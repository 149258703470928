import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './about0.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BackgroundImage from 'img/photos/about/0/background.png';

import StoryImage0 from 'img/photos/about/0/story_0.png';
import StoryImage1 from 'img/photos/about/0/story_1.png';
import StoryImage2 from 'img/photos/about/0/story_2.png';
import StoryImage3 from 'img/photos/about/0/story_3.png';

const WhyDatas = [
  {
    title: 'For Women',
    title2: '여성중심',
    content: '오직 여성을 위한,\n여성 중심의 진료 프로세스 구축',
  },
  {
    title: 'Personal Counseling',
    title2: '1:1 맞춤',
    content: '질환별, 환자별\n산부인과 전문의 1:1맞춤 치료',
  },
  {
    title: 'Responsibility',
    title2: '책임 진료',
    content: '상담부터 후 관리까지,\n대표원장 책임 진료',
  },
];

function About0(): ReactElement {
  const menuIndex = 0;
  const subIndex = 0;

  return (
    <>
      <Header />

      <Common.Container>
        <Styled.TitleContainer>
          <Common.SizedBoxH height={80} />
          <Typo.Pretendard_ExtraBold fontSize={30} color={Colors.black}>
            ARA Story
          </Typo.Pretendard_ExtraBold>
          <Common.SizedBoxH height={4} />
          <Typo.Pretendard_Regular fontSize={15} color={Colors.gray200}>
            여성의 건강만을 생각하는 아라산부인과의원을 소개합니다.
          </Typo.Pretendard_Regular>
        </Styled.TitleContainer>

        <Common.Contents backgroundImage={BackgroundImage}>
          <Common.SizedBoxH height={171} />
          <Common.FlexRow>
            <Typo.Pretendard_Light fontSize={20} color={Colors.gray200}>
              당신의&nbsp;
            </Typo.Pretendard_Light>
            <Typo.Pretendard_Medium fontSize={20} color={Colors.gray200}>
              마음
            </Typo.Pretendard_Medium>
            <Typo.Pretendard_Light fontSize={20} color={Colors.gray200}>
              을 깊이 알아주는 곳
            </Typo.Pretendard_Light>
          </Common.FlexRow>
          <Typo.Pretendard_ExtraBold fontSize={20} color={Colors.gold300}>
            아라산부인과
          </Typo.Pretendard_ExtraBold>

          <Common.SizedBoxH height={10} />

          <Common.SizedBox width={334} height={151}>
            <Common.Fill color={'rgba(235, 226, 204, 0.50)'}>
              <Common.FlexColumn width={334} height={131} alignItems="center">
                <Common.SizedBoxH height={16} />
                <Typo.Pretendard_Regular width={276} fontSize={15} color={Colors.gray300} lineHeight={20}>
                  아라산부인과 의료진은 언제나 여성의 편에 서서 말 못할 고민과 걱정을 함께 공감하며,
                  <br />
                  건강을 지킬 수 있도록 끊임없이 노력합니다.
                  <br />
                  <br />
                  환자 한 분 한 분 성심성의껏
                  <br />
                  진료할 것을 약속 드립니다.
                </Typo.Pretendard_Regular>
              </Common.FlexColumn>
            </Common.Fill>
          </Common.SizedBox>

          <Common.SizedBoxH height={20} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={86} />

          <Styled.StoryContainer>
            <Styled.StoryIndexContainer>
              <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                Story
              </Typo.Pretendard_Light>
              <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                01
              </Typo.Pretendard_Regular>
            </Styled.StoryIndexContainer>

            <Styled.StoryIconContainer>
              <Common.SizedImage width={158} height={159} src={StoryImage0} />
            </Styled.StoryIconContainer>

            <Styled.StoryTextContainer>
              <Styled.StoryTitleText>
                연세 세브란스 출신 <mark>유능한 전문의</mark>
              </Styled.StoryTitleText>
              <Common.SizedBoxH height={12} />
              <Typo.Pretendard_Regular textAlign="left" fontSize={15} color={Colors.gray200}>
                풍부한 임상 경험과 노하우를
                <br />
                갖춘 실력 있는 전문의가 진료합니다.
              </Typo.Pretendard_Regular>
            </Styled.StoryTextContainer>
          </Styled.StoryContainer>

          <Common.SizedBoxH height={69} />

          <Styled.StoryContainer>
            <Styled.StoryIndexContainer>
              <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                Story
              </Typo.Pretendard_Light>
              <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                02
              </Typo.Pretendard_Regular>
            </Styled.StoryIndexContainer>

            <Styled.StoryIconContainer>
              <Common.SizedImage width={334} height={200} objectFit="cover" src={StoryImage1} />
            </Styled.StoryIconContainer>

            <Styled.StoryTextContainer>
              <Styled.StoryTitleText>
                <mark>여성</mark> 산부인과 전문의
              </Styled.StoryTitleText>
              <Common.SizedBoxH height={12} />
              <Typo.Pretendard_Regular textAlign="left" fontSize={15} color={Colors.gray200}>
                여성을 누구보다 가장 잘 아는
                <br />
                여성 산부인과 전문의가 케어합니다.
              </Typo.Pretendard_Regular>
            </Styled.StoryTextContainer>
          </Styled.StoryContainer>

          <Common.SizedBoxH height={69} />

          <Styled.StoryContainer>
            <Styled.StoryIndexContainer>
              <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                Story
              </Typo.Pretendard_Light>
              <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                03
              </Typo.Pretendard_Regular>
            </Styled.StoryIndexContainer>

            <Styled.StoryIconContainer>
              <Common.SizedImage width={334} height={200} objectFit="cover" src={StoryImage2} />
            </Styled.StoryIconContainer>

            <Styled.StoryTextContainer>
              <Styled.StoryTitleText>
                <mark>정직한</mark> 진료와 수술
              </Styled.StoryTitleText>
              <Common.SizedBoxH height={12} />
              <Typo.Pretendard_Regular textAlign="left" fontSize={15} color={Colors.gray200}>
                최선의 결과를 위해 과잉 진료 없이
                <br />
                정직한 진료와 수술만을 약속합니다.
              </Typo.Pretendard_Regular>
            </Styled.StoryTextContainer>
          </Styled.StoryContainer>

          <Common.SizedBoxH height={69} />

          <Styled.StoryContainer>
            <Styled.StoryIndexContainer>
              <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
                Story
              </Typo.Pretendard_Light>
              <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
                04
              </Typo.Pretendard_Regular>
            </Styled.StoryIndexContainer>

            <Styled.StoryIconContainer>
              <Common.SizedImage width={334} height={200} objectFit="cover" src={StoryImage3} />
            </Styled.StoryIconContainer>

            <Styled.StoryTextContainer>
              <Styled.StoryTitleText>
                <mark>친절</mark> 진료 및 상담
              </Styled.StoryTitleText>
              <Common.SizedBoxH height={12} />
              <Typo.Pretendard_Regular textAlign="left" fontSize={15} color={Colors.gray200}>
                내원 환자들의 불안감을 덜기 위해
                <br />
                언제나 밝고 친절한 상담을 약속합니다.
              </Typo.Pretendard_Regular>
            </Styled.StoryTextContainer>
          </Styled.StoryContainer>

          <Common.SizedBoxH height={46} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={46} />

          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            ARA WOMEN’S CLINIC
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={27} />

          {WhyDatas.map((e) => (
            <>
              <Styled.WhyCard key={e.title}>
                <Styled.WhyCardViewport>
                  <Common.SizedBoxH height={23} />
                  <Typo.BodoniMT_BoldItalic fontSize={20} textAlign="right" color={Colors.gold300}>
                    {e.title}
                  </Typo.BodoniMT_BoldItalic>
                  <Common.SizedBoxH height={4} />
                  <Typo.Pretendard_Medium fontSize={20} color={Colors.gray300} textAlign="right">
                    {e.title2}
                  </Typo.Pretendard_Medium>
                  <Common.SizedBoxH height={8} />
                  <Typo.Pretendard_Regular fontSize={15} color="#958E78" textAlign="right">
                    {e.content}
                  </Typo.Pretendard_Regular>
                </Styled.WhyCardViewport>
              </Styled.WhyCard>
              <Common.SizedBoxH height={10} />
            </>
          ))}
          <Common.SizedBoxH height={58} />
        </Common.Contents>
      </Common.Container>

      <Footer />
    </>
  );
}

export default About0;
