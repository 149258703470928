import styled from 'styled-components';
import Colors from './colors';
import { vw } from './utils';

export const TitleDescription = styled.div`
  width: ${vw(334)}px;
  min-height: ${vw(265)}px;
  background-color: ${Colors.gold200};
  padding: ${vw(47)}px ${vw(24)}px;

  color: ${Colors.gray300};
  text-align: left;
  font-family: Pretendard;
  font-size: ${vw(15)}px;
  font-style: normal;
  font-weight: 400;
  line-height: ${vw(25)}px; /* 166.667% */
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;

  padding: ${vw(64)}px 0 0;
`;

export const Contents = styled.div<{ backgroundImage?: any; backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 0 ${vw(20)}px;

  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : Colors.white)};

  ${(p) => p.backgroundImage && `background-image: url(${p.backgroundImage})`};
  ${(p) => p.backgroundImage && 'background-position: center'};
  ${(p) => p.backgroundImage && 'background-size: cover'};
`;

export const SizedBoxW = styled.div<{ width: number }>`
  width: ${(p) => vw(p.width)}px;
  height: 100%;
`;

export const SizedBoxH = styled.div<{ height: number }>`
  width: 100%;
  height: ${(p) => vw(p.height)}px;
`;

export const SizedBox = styled.div<{ width: number; height: number; backgroundColor?: string }>`
  width: ${(p) => vw(p.width)}px;
  height: ${(p) => vw(p.height)}px;
  ${(p) => p.backgroundColor && `background-color: ${p.backgroundColor}`};
`;

export const FlexRow = styled.div<{ width?: number; height?: number; alignItems?: string; justifyContent?: string; flexWrap?: string }>`
  display: flex;
  ${(p) => p.width && `width: ${vw(p.width)}px`};
  ${(p) => p.height && `height: ${vw(p.height)}px`};
  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};
  ${(p) => p.justifyContent && `justify-content: ${p.justifyContent}`};
  ${(p) => p.flexWrap && `flex-wrap: ${p.flexWrap}`}
`;

export const FlexColumn = styled.div<{ width?: number; height?: number; alignItems?: string; justifyContent?: string }>`
  display: flex;
  flex-direction: column;
  ${(p) => p.width && `width: ${vw(p.width)}px`};
  ${(p) => p.height && `height: ${vw(p.height)}px`};
  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};
  ${(p) => p.justifyContent && `justify-content: ${p.justifyContent}`};
`;

export const Dash = styled.div<{ width: number; height: number; color?: string }>`
  width: ${(p) => vw(p.width)}px;
  height: ${(p) => vw(p.height)}px;

  border: ${vw(0.5)} dashed ${(p) => (p.color ? p.color : Colors.white)};
`;

export const Fill = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.color};
`;

export const Circle = styled.div<{ width: number; height: number; backgroundColor?: string }>`
  width: ${(p) => vw(p.width)}px;
  height: ${(p) => vw(p.height)}px;
  background: ${(p) => (p.backgroundColor ? p.backgroundColor : Colors.white)};
  border-radius: 50%;
  overflow: hidden;
`;

export const NoOpacityButton = styled.button<{
  width?: number;
  height?: number;
}>`
  padding: 0;
  border: none;
  background-color: transparent;

  ${(p) => p.width && `width: ${vw(p.width)}px`};
  ${(p) => p.height && `height: ${vw(p.height)}px`};
`;

export const Span = styled.span`
  margin: auto;
`;

export const SizedImage = styled.img<{ width?: number; height?: number; objectFit?: string }>`
  ${(p) => p.width && `width: ${vw(p.width)}px`};
  ${(p) => p.height && `height: ${vw(p.height)}px`};
  ${(p) => p.objectFit && `object-fit: ${p.objectFit}`};
`;
