import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './about4.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

function About4(): ReactElement {
  const menuIndex = 0;
  const subIndex = 0;

  return (
    <>
      <Header />

      <Styled.Container>
        <Styled.TitleContainer></Styled.TitleContainer>
      </Styled.Container>

      <Footer />
    </>
  );
}

export default About4;
