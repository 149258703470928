import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import * as Styled from 'App.style';
import * as Components from 'components';
import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// fonts
import PretendardExtraLight from 'fonts/Pretendard/Pretendard-ExtraLight.otf'; // 200
import PretendardLight from 'fonts/Pretendard/Pretendard-Light.otf'; // 300
import PretendardRegular from 'fonts/Pretendard/Pretendard-Regular.otf'; // 400
import PretendardMedium from 'fonts/Pretendard/Pretendard-Medium.otf'; // 500
import PretendardExtraBold from 'fonts/Pretendard/Pretendard-ExtraBold.otf'; // 800

import Baskerville from 'fonts/baskerville/BASKVILL.ttf';

import InterRegular from 'fonts/Inter/Inter-Regular.ttf';

import BodoniMTBoldItalic from 'fonts/BodoniMT/BOD_BI.ttf';

import BodoniModaItalic from 'fonts/BodoniModa/BodoniModaItalic.ttf';

import AbrilRegular from 'fonts/Abril_Fatface/AbrilFatface-Regular.ttf';

// context

import { NavermapsProvider } from 'react-naver-maps';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Pretendard;
    font-display: fallback;
    font-weight: 200;
    src: url(${PretendardExtraLight}) format('opentype');
  }

  @font-face {
    font-family: Pretendard;
    font-display: fallback;
    font-weight: 300;
    src: url(${PretendardLight}) format('opentype');
  }

  @font-face {
    font-family: Pretendard;
    font-display: fallback;
    font-weight: 400;
    src: url(${PretendardRegular}) format('opentype');
  }

  @font-face {
    font-family: Pretendard;
    font-display: fallback;
    font-weight: 500;
    src: url(${PretendardMedium}) format('opentype');
  }

  @font-face {
    font-family: Pretendard;
    font-display: fallback;
    font-weight: 800;
    src: url(${PretendardExtraBold}) format('opentype');
  }

  @font-face {
    font-family: Inter;
    font-display: fallback;
    src: url(${InterRegular}) format('truetype');
  }

  @font-face {
    font-family: Baskerville;
    font-display: fallback;
    src: url(${Baskerville}) format('truetype');
  }

  @font-face {
    font-family: BodoniMT;
    font-display: fallback;
    font-weight: 700;
    font-style: italic;
    src: url(${BodoniMTBoldItalic}) format('truetype');
  }

  @font-face {
    font-family: BodoniModa;
    font-display: fallback;
    font-style: italic;
    src: url(${BodoniModaItalic}) format('truetype');
  }

  @font-face {
    font-family: Abril;
    font-display: fallback;
    src: url(${AbrilRegular}) format('truetype');
  }

  body {
    font-family: Pretendard, Baskerville, Inter, BodoniMT;
  }
`;

export default function App(): ReactElement {
  return (
    <NavermapsProvider
      ncpClientId="pu5jptq5dc"
      // or finClientId, govClientId
    >
      <Styled.Container>
        <GlobalStyle />
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route element={<Components.Main />} path="/" />
            <Route path="about">
              <Route path="0" element={<Components.Abouts.About0 />} />
              <Route path="1" element={<Components.Abouts.About1 />} />
              <Route path="2" element={<Components.Abouts.About2 />} />
              <Route path="3" element={<Components.Abouts.About3 />} />
              <Route path="4" element={<Components.Abouts.About4 />} />
            </Route>

            <Route path="pregnant">
              <Route path="0" element={<Components.Pregnants.Pregnant0 />} />
              <Route path="1" element={<Components.Pregnants.Pregnant1 />} />
              <Route path="2" element={<Components.Pregnants.Pregnant2 />} />
            </Route>

            <Route path="disease">
              <Route path="0" element={<Components.Diseases.Disease0 />} />
              <Route path="1" element={<Components.Diseases.Disease1 />} />
              <Route path="2" element={<Components.Diseases.Disease2 />} />
              <Route path="3" element={<Components.Diseases.Disease3 />} />
              <Route path="4" element={<Components.Diseases.Disease4 />} />
              <Route path="5" element={<Components.Diseases.Disease5 />} />
              <Route path="6" element={<Components.Diseases.Disease6 />} />
              <Route path="7" element={<Components.Diseases.Disease7 />} />
              <Route path="8" element={<Components.Diseases.Disease8 />} />
              <Route path="9" element={<Components.Diseases.Disease9 />} />
              <Route path="10" element={<Components.Diseases.Disease10 />} />
            </Route>

            <Route path="checkup">
              <Route path="0" element={<Components.CheckUps.CheckUp0 />} />
              <Route path="1" element={<Components.CheckUps.CheckUp1 />} />
              <Route path="2" element={<Components.CheckUps.CheckUp2 />} />
            </Route>

            <Route path="aracare">
              <Route path="0" element={<Components.AraCares.AraCare0 />} />
              <Route path="1" element={<Components.AraCares.AraCare1 />} />
              <Route path="2" element={<Components.AraCares.AraCare2 />} />
              <Route path="3" element={<Components.AraCares.AraCare3 />} />
              <Route path="4" element={<Components.AraCares.AraCare4 />} />
            </Route>

            <Route path="uninsured" element={<Components.Uninsured />} />
            <Route path="rights" element={<Components.Rights />} />
          </Routes>
        </BrowserRouter>
      </Styled.Container>
    </NavermapsProvider>
  );
}
