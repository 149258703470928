import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/10/banner.png';

import SymptomImage0 from 'img/photos/disease/10/symptomImage/0.png';
import SymptomImage1 from 'img/photos/disease/10/symptomImage/1.png';
import SymptomImage2 from 'img/photos/disease/10/symptomImage/2.png';
import SymptomImage3 from 'img/photos/disease/10/symptomImage/3.png';
import SymptomImage4 from 'img/photos/disease/10/symptomImage/4.png';

const SymptomDatas = [
  {
    title: '안면홍조',
    content: '얼굴이 화끈거리며 빨갛게 달아오르고\n땀을 비오듯 흘리기도 합니다.',
    image: SymptomImage0,
  },
  {
    title: '정신적 불안정',
    content: '우울증, 정서불안, 신경과민,\n기억력 감소 등의 증상이 나타납니다.',
    image: SymptomImage1,
  },
  {
    title: '수면장애',
    content: '밤에 충분히 수면을 취하지 못하고\n만성피로, 두통을 유발합니다.',
    image: SymptomImage2,
  },
  {
    title: '피부 노화',
    content: '에스트로겐 분비가 감소해 피부 탄력이\n떨어지고 주름이 증가합니다.',
    image: SymptomImage3,
  },
  {
    title: '골다공증',
    content: '에스트로겐이 감소함에 따라 골흡수와 형성에\n불균형을 초래해 골다공증이 발생합니다.',
    image: SymptomImage4,
  },
];

import CheckUpImage0 from 'img/photos/disease/0/check_0.png';
import CheckUpImage1 from 'img/photos/disease/0/check_0.png';
import CheckUpImage2 from 'img/photos/disease/0/check_0.png';
import CheckUpImage3 from 'img/photos/disease/0/check_0.png';
import CheckUpImage4 from 'img/photos/disease/0/check_0.png';
import CheckUpImage5 from 'img/photos/disease/0/check_0.png';

const CheckUpDatas = [
  {
    title: '혈액 검사',
    image: CheckUpImage0,
  },
  {
    title: '초음파 검사',
    image: CheckUpImage1,
  },
  {
    title: '자궁경부암 검사',
    image: CheckUpImage2,
  },
  {
    title: '유방촬영술',
    image: CheckUpImage3,
  },
  {
    title: '유방 초음파',
    image: CheckUpImage4,
  },
  {
    title: '골밀도 검사',
    image: CheckUpImage5,
  },
];

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';
import TreatmentImage2 from 'img/photos/disease/4/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '약물 치료',
    description: '리비알, 안젤릭, 크리멘 등 투약 합니다. ',
    image: TreatmentImage0,
  },
  {
    title: '주사 치료',
    description: '멜스몬(태반주사)를 사용할 수 있습니다.',
    image: TreatmentImage1,
  },
  {
    title: '개인별 일대일 맞춤 치료',
    description: '규칙적인 운동 및 맞춤 치료 설계.',
    image: TreatmentImage2,
  },
];

function Disease10(): ReactElement {
  const menuIndex = 2;
  const subIndex = 10;

  return (
    <>
      <Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />

          <Common.SizedImage src={BannerImage} width={334} height={264} objectFit="cover" />
          <Common.TitleDescription>
            난소가 노화되어 기능이 떨어지면 배란 및 여성호르몬의 생산이 더 이상 이루어지지 않는데, 이로 인해 나타나는 현상이 바로
            폐경입니다. 대개 1년간 생리가 없을 때 폐경으로 진단합니다. 이러한 변화는 대개 40대 중후반에서 시작되어 점진적으로 진행되는데,
            이때부터 생리가 완전히 없어지는 폐경이 나타난 이후의 약 1년까지를 폐경이행기, 더 흔히는 갱년기라고 하며 그 기간은 평균 4~7년
            정도입니다.
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            폐경기 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />
          {SymptomDatas.map((e, i) => (
            <>
              <Components.Common.IndexLineContent data={{ ...e, index: i }} />
              {i < SymptomDatas.length - 1 && <Common.SizedBoxH height={20} />}
            </>
          ))}

          <Common.SizedBoxH height={50} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            폐경기호르몬요법 위한
            <br />
            필요검사
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          {CheckUpDatas.map((e, i) => (
            <>
              <Components.Common.ImageCard data={e} />
              <Common.SizedBoxH height={20} />
            </>
          ))}
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            호르몬 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {TreatmentDatas.map((e, i) => (
            <>
              <Components.Common.IndexCard data={{ ...e, index: i, indexString: 'Treat', content: e.description }} />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Disease10;
