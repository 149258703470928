import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './aracare4.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import StepIcon0 from 'img/photos/aracare/3/step_0.png';
import StepIcon1 from 'img/photos/aracare/3/step_1.png';
import StepIcon2 from 'img/photos/aracare/3/step_2.png';
import StepIcon3 from 'img/photos/aracare/3/step_3.png';
import StepIcon4 from 'img/photos/aracare/3/step_4.png';
import StepIcon5 from 'img/photos/aracare/3/step_5.png';

const StepDatas = [
  {
    icon: StepIcon0,
    title: '시술시간',
    content: '대부분 15분 소요',
  },
  {
    icon: StepIcon1,
    title: '마취방법',
    content: '없음',
  },
  {
    icon: StepIcon2,
    title: '입원치료',
    content: '당일퇴원',
  },
  {
    icon: StepIcon3,
    title: '내원횟수',
    content: '1회',
  },
  {
    icon: StepIcon4,
    title: '회복기간',
    content: '즉시',
  },
  {
    icon: StepIcon5,
    title: '부부관계',
    content: '당일 가능',
  },
];

import BackgroumdImage0 from 'img/photos/aracare/4/background_1.png';
import BackgroundImage1 from 'img/photos/aracare/3/background.png';

import BeforeImage from 'img/photos/aracare/3/bafore.png';
import AfterImage from 'img/photos/aracare/3/after.png';
import RightArrowImage from 'img/photos/aracare/3/arrow.png';
import CheckImage from 'img/photos/disease/4/check.png';

const PointDatas = ['자궁 경부와\n질 진찰', '질 내부 소독', '세정제/유산균\n도포', '진정 관리 및\n아로마 테라피'];

const RecommandDatas = [
  '부부관계 전후로 위생 관리를 원하는 경우',
  '반복되는 질염 치료를 원하는 경우',
  '생리 전후로 냄새와 가려움 등의 불편함이 있는 경우',
  '공중목욕탕, 수영장 이용 후 청결 관리를 원하는 경우',
];

function AraCare4(): ReactElement {
  const menuIndex = 4;
  const subIndex = 4;

  return (
    <>
      <Header />

      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Common.FlexRow width={335} alignItems="center" justifyContent="space-between">
            {StepDatas.slice(0, 3).map((e, i) => (
              <Styled.StepContainer>
                <Common.SizedBoxH height={18} />
                <Styled.StepIconContainer>
                  <Common.SizedImage src={e.icon} width={18} height={18} />
                </Styled.StepIconContainer>

                <Common.SizedBoxH height={11} />

                <Typo.Pretendard_Medium fontSize={15} color={Colors.gold200}>
                  {e.title}
                </Typo.Pretendard_Medium>

                <Common.SizedBoxH height={7} />

                <Common.Dash width={52} height={0} color={Colors.gold200} />

                <Common.SizedBoxH height={9} />

                <Typo.Pretendard_Light fontSize={12} color={Colors.gray200}>
                  {e.content}
                </Typo.Pretendard_Light>
              </Styled.StepContainer>
            ))}
          </Common.FlexRow>

          <Common.SizedBoxH height={10} />

          <Common.FlexRow width={335} alignItems="center" justifyContent="space-between">
            {StepDatas.slice(3, 6).map((e, i) => (
              <Styled.StepContainer>
                <Common.SizedBoxH height={18} />
                <Styled.StepIconContainer>
                  <Common.SizedImage src={e.icon} width={18} height={18} />
                </Styled.StepIconContainer>

                <Common.SizedBoxH height={11} />

                <Typo.Pretendard_Medium fontSize={15} color={Colors.gold200}>
                  {e.title}
                </Typo.Pretendard_Medium>

                <Common.SizedBoxH height={7} />

                <Common.Dash width={52} height={0} color={Colors.gold200} />

                <Common.SizedBoxH height={9} />

                <Typo.Pretendard_Light fontSize={12} color={Colors.gray200}>
                  {e.content}
                </Typo.Pretendard_Light>
              </Styled.StepContainer>
            ))}
          </Common.FlexRow>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundImage={BackgroumdImage0}>
          <Common.SizedBoxH height={20} />

          <Typo.Pretendard_Medium fontSize={10} color={Colors.gold200}>
            About
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            아라산부인과
            <br />
            질스케일링
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={270} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={50} />
          <Typo.Pretendard_Regular fontSize={15} lineHeight={30} color={Colors.gray300}>
            여성의 질은산성 상태를 유지해 외부 병원균의 침입을 막고
            <br />
            질 내 미생물 성장을 억제합니다.
            <br />
            그러나 질 내 이물질, 면역력 저하 여러 원인에 의해
            <br />
            질 내 환경에 변화가 생기면 세균 감염에 취약해져 여성 질환을 초래합니다.
            <br />
            <br />
            질스케일링은 질 주름 사이의 생리 불순물과 냉대하 등을 제거하고
            <br />
            질 내부가 적정 산도를 유지할 수 있도록 도움을 주어
            <br />
            다양한 감염성 여성 질환을 예방하고 치료합니다.
          </Typo.Pretendard_Regular>
          <Common.SizedBoxH height={50} />
        </Common.Contents>

        <Common.Contents backgroundImage={BackgroundImage1}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            How
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.gray300}>
            아라산부인과
            <br />
            질스케일링 방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={42} />

          <Common.FlexRow alignItems="center" justifyContent="center">
            {PointDatas.slice(0, 2).map((e, i) => (
              <>
                <Styled.PointContainer key={e}>
                  <Typo.Pretendard_Regular fontSize={15} lineHeight={20} color={Colors.gray300}>
                    {e}
                  </Typo.Pretendard_Regular>
                  <Styled.PointIndexContainer>
                    <Typo.Pretendard_Light height={12} color={Colors.white} fontSize={15}>
                      Step
                    </Typo.Pretendard_Light>
                    <Typo.Pretendard_Regular height={30} color={Colors.white} fontSize={30}>
                      0{i + 1}
                    </Typo.Pretendard_Regular>
                  </Styled.PointIndexContainer>
                </Styled.PointContainer>
                {i == 0 && <Common.SizedBoxW width={60} />}
              </>
            ))}
          </Common.FlexRow>
          <Common.SizedBoxH height={60} />
          <Common.FlexRow alignItems="center" justifyContent="center">
            {PointDatas.slice(2, 4).map((e, i) => (
              <>
                <Styled.PointContainer key={e}>
                  <Typo.Pretendard_Regular fontSize={15} lineHeight={20} color={Colors.gray300}>
                    {e}
                  </Typo.Pretendard_Regular>
                  <Styled.PointIndexContainer>
                    <Typo.Pretendard_Light height={12} color={Colors.white} fontSize={15}>
                      Step
                    </Typo.Pretendard_Light>
                    <Typo.Pretendard_Regular height={30} color={Colors.white} fontSize={30}>
                      0{i + 3}
                    </Typo.Pretendard_Regular>
                  </Styled.PointIndexContainer>
                </Styled.PointContainer>
                {i == 0 && <Common.SizedBoxW width={60} />}
              </>
            ))}
          </Common.FlexRow>
          <Common.SizedBoxH height={60} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Who
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            질스케일링 추천 대상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={10} />

          <Typo.Pretendard_Regular fontSize={20} color={Colors.gray200}>
            이런 분들에게 추천을 드립니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={20} />
          <Components.Common.PaperCheckList data={RecommandDatas} />
          <Common.SizedBoxH height={40} />
        </Common.Contents>
      </Common.Container>

      <Footer />
    </>
  );
}

export default AraCare4;
