import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';

import styled from 'styled-components';
import { Colors } from 'common';
import { vw } from 'common/utils';

import SymptomContainerImage from 'img/common/SymptomContainer.png';
import SymptomArrow from 'img/photos/disease/Arrow.svg';

const Container = styled.div`
  width: ${vw(335)}px;
  display: flex;
  align-items: stretch;
`;

const SymptomBalloon = styled.div`
  width: ${vw(60)}px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${vw(10)}px;
  background: var(--ebe-2-cc, #ebe2cc);
`;

const SymptomContainer = styled.div`
  width: ${vw(255)}px;
  min-height: ${vw(80)}px;

  border-radius: ${vw(10)}px;
  border: 1px solid var(--ebe-2-cc, #ebe2cc);

  background-image: url(${SymptomContainerImage});
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: ${vw(15)}px ${vw(10)}px;
`;

const SymptomList = styled.div`
  width: ${vw(235)}px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

function Symptoms(props: { data: string[] }): ReactElement {
  const { data } = props;
  return (
    <Container>
      <SymptomBalloon>
        <Typo.Pretendard_Regular fontSize={15} color={'#665E4B'}>
          증상
        </Typo.Pretendard_Regular>
      </SymptomBalloon>

      <Common.FlexRow alignItems="center">
        <Common.SizedImage style={{ transform: 'translateX(-1px)' }} src={SymptomArrow} width={10} height={16} />
      </Common.FlexRow>

      <Common.SizedBoxW width={10} />

      <SymptomContainer>
        {data.map((e, i) => (
          <>
            <SymptomList>
              <Typo.Abril width={15} lineHeight={25} fontSize={15} color="#665E4B">
                {i + 1}.
              </Typo.Abril>
              <Typo.Pretendard_Regular width={215} textAlign="left" fontSize={15} lineHeight={25} color={Colors.gray200}>
                {e}
              </Typo.Pretendard_Regular>
            </SymptomList>
            {i < data.length - 1 && <Common.SizedBoxH height={15} />}
          </>
        ))}
      </SymptomContainer>
    </Container>
  );
}

export default Symptoms;
