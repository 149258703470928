import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';
import CauseContainerImage from 'img/photos/disease/4/cause_container.png';
import { vw } from 'common/utils';

export const WhyContainer = styled.div`
  width: ${vw(274)}px;
  height: ${vw(274)}px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(180deg, rgba(206, 151, 21, 0.1) 0%, rgba(155, 116, 22, 0) 50%, rgba(206, 151, 21, 0.1) 100%), #ebe2cc;
`;

export const WhyViewport = styled.div`
  width: ${vw(234)}px;
  height: ${vw(234)}px;

  padding: ${vw(6)}px ${vw(10)}px;

  display: flex;
  flex-direction: column;

  border-radius: 0px 0px 0px ${vw(80)}px;
  border: 1px solid #fff;
`;

export const WhyImageContainer = styled.img<{ width: number; height: number; top: number; left: number }>`
  position: absolute;
  top: ${(p) => vw(p.top)}px;
  left: ${(p) => vw(p.left)}px;
  object-fit: contain;
  width: ${(p) => vw(p.width)}px;
  height: ${(p) => vw(p.height)}px;
`;
