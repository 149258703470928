import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';
import { vw } from 'common/utils';

export const CauseCard = styled.div`
  width: ${vw(230)}px;
  height: ${vw(230)}px;

  border-radius: ${vw(10)}px;

  background: #ebe2cc;

  position: relative;
`;

export const CauseCardIconContainer = styled.div`
  width: ${vw(80)}px;
  height: ${vw(80)}px;

  border-radius: 50%;

  background: #fff;
  box-shadow: 0px ${vw(4)}px ${vw(4)}px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  top: ${vw(-40)}px;
  left: calc((100% - ${vw(80)}px) / 2);
`;

export const CauseCardViewport = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
