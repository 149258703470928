import { Colors } from 'common';
import { vw } from 'common/utils';
import styled, { createGlobalStyle } from 'styled-components';

export const CheckUpButton = styled.button`
  width: ${vw(100)}px;
  height: ${vw(40)}px;

  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Pretendard;
  font-weight: 400;
  text-align: center;
  font-size: ${vw(15)}px;

  background-color: ${Colors.white};
  color: ${Colors.gold300};

  border: 1px solid ${Colors.gold300};
  :disabled {
    background-color: ${Colors.gold300};
    color: ${Colors.white};
  }
`;
