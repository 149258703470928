import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';

import styled from 'styled-components';
import { Colors } from 'common';
import { vw } from 'common/utils';

const Container = styled.div`
  width: ${vw(335)}px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ImageContainer = styled.div`
  width: ${vw(335)}px;
  height: ${vw(225)}px;

  background-color: ${Colors.gold100};

  display: flex;
  align-items: center;
  justify-content: center;
`;

function IndexLineContent(props: {
  data: {
    index: number;
    title?: string;
    content?: string;
    image?: any;
    imageObjectFit?: string;
    imageWidth?: number;
    imageHeight?: number;
  };
}): ReactElement {
  const { index, title, content, image, imageWidth, imageHeight, imageObjectFit } = props.data;
  return (
    <Container>
      <Typo.BodoniMT_BoldItalic verticalAlign="bottom" textAlign="left" height={46} fontSize={50} color={Colors.gray200}>
        0{index + 1}
      </Typo.BodoniMT_BoldItalic>

      <Common.SizedBox width={335} height={2}>
        <Common.Fill color={Colors.gray200} />
      </Common.SizedBox>

      {image && (
        <>
          <Common.SizedBoxH height={10} />
          <ImageContainer>
            <Common.SizedImage width={imageWidth ?? 335} height={imageHeight ?? 225} src={image} objectFit={imageObjectFit ?? 'cover'} />
          </ImageContainer>
        </>
      )}

      <Common.SizedBoxH height={27} />

      {title && (
        <>
          <Typo.Pretendard_Medium textAlign="left" fontSize={20} color={Colors.gray200}>
            {title}
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={14} />
        </>
      )}

      {content && (
        <>
          <Typo.Pretendard_Regular lineHeight={20} textAlign="left" fontSize={15} color={Colors.gray200}>
            {content}
          </Typo.Pretendard_Regular>
          <Common.SizedBoxH height={13} />
        </>
      )}
    </Container>
  );
}

export default IndexLineContent;
