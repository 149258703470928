import styled from 'styled-components';
import { Colors } from 'common';
import * as Typo from 'common/typography';
import { vw } from 'common/utils';

export const TitleText = styled(Typo.Pretendard_Light)`
  color: ${Colors.black};
  font-size: ${vw(30)}px;

  mark {
    font-weight: 700;
    color: ${Colors.black};
    background: none;
  }
`;

export const TableContainer = styled.div`
  width: ${vw(335)}px;
  overflow: scroll;
`;

export const Table = styled.table`
  width: ${vw(1160)}px;

  border-collapse: collapse;

  border: 1px solid #d9d9d9;

  th {
    color: var(--333333, #333);
    text-align: center;
    font-family: Pretendard;
    font-size: ${vw(16)}px;
    font-style: normal;
    font-weight: 700;
    line-height: ${vw(22)}px; /* 137.5% */
    background-color: ${Colors.gold100};

    border: 1px solid #d9d9d9;
  }

  td {
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: ${vw(16)}px;
    font-style: normal;
    font-weight: 400;

    border: 1px solid #d9d9d9;

    height: ${vw(35)}px;
  }
`;
