import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease3.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/3/banner.png';

import TypeImage0 from 'img/photos/disease/3/type_0.png';
import TypeImage1 from 'img/photos/disease/3/type_1.png';

import CauseIcon0 from 'img/photos/disease/2/causeIcons/0.png';
import CauseIcon1 from 'img/photos/disease/2/causeIcons/1.png';
import CauseIcon2 from 'img/photos/disease/2/causeIcons/2.png';

const CauseDatas = [
  {
    title: '자궁 • 난소질환',
    contents: ['자궁내막증', '자궁내막, 경부 용종', '근종, 선근종', '자궁내막암, 경부암'],
    icon: CauseIcon0,
  },
  {
    title: '호르몬 이상',
    contents: ['조기폐경', '갑상선 질환', '고프로락틴혈증'],
    icon: CauseIcon1,
  },
  {
    title: '일시적 • 생리적 이상',
    contents: ['무배란', '스트레스', '약물'],
    icon: CauseIcon2,
  },
];

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import CheckImage0 from 'img/photos/disease/0/check_0.png';
import CheckImage1 from 'img/photos/disease/0/check_1.png';

const HowToCheckDatas = [
  {
    title: '혈액 검사',
    image: CheckImage0,
  },
  {
    title: '초음파 검사',
    image: CheckImage1,
  },
];

function Disease3(): ReactElement {
  const menuIndex = 2;
  const subIndex = 3;

  return (
    <>
      <Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />
          <Common.SizedImage src={BannerImage} width={334} height={264} objectFit="cover" />
          <Common.TitleDescription>
            단순 복통, 월경통으로 인한 것 뿐만 아니라
            <br />
            자궁근종, 자궁내막증, 난소낭종 등 다양한 여성 질환이 원인일 수 있습니다. 따라서 별다른 이유 없이 복통이 지속된다면 내원하여
            명확한 원인을 파악해야 합니다.
          </Common.TitleDescription>
          <Common.SizedBoxH height={44} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            난소에 발생하는 혹
            <br />
            (난소 꼬임)
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Common.SizedImage src={TypeImage0} width={335} height={250} objectFit="cover" />

          <Common.SizedBoxH height={20} />

          <Styled.Content1Title>
            <Typo.Pretendard_Regular fontSize={24} color={Colors.gray300}>
              기능성 난소 낭종
            </Typo.Pretendard_Regular>
          </Styled.Content1Title>

          <Common.SizedBoxH height={20} />

          <Typo.Pretendard_Medium textAlign="center" fontSize={20} color={Colors.black}>
            여포낭종, 황체낭종, 난포막 황체화 낭종 등
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={20} />

          <Typo.Pretendard_Regular textAlign="center" fontSize={15} lineHeight={25} color={Colors.gray200}>
            대부분 젊은 사람에게서 발생하는 낭종입니다.
            <br />
            배란 과정에서 장애가 있을 시 발생하며, 자연 소실 되는 경우가 많습니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={40} />

          <Common.SizedImage src={TypeImage1} width={335} height={250} />

          <Common.SizedBoxH height={20} />

          <Styled.Content1Title>
            <Typo.Pretendard_Regular fontSize={24} color={Colors.gray300}>
              양성 난소 낭종
            </Typo.Pretendard_Regular>
          </Styled.Content1Title>

          <Common.SizedBoxH height={20} />

          <Typo.Pretendard_Medium textAlign="center" fontSize={20} color={Colors.black}>
            기형종, 장액성 또는 점액성 낭종 등
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={20} />

          <Typo.Pretendard_Regular textAlign="center" fontSize={15} lineHeight={25} color={Colors.gray200}>
            원인이 뚜렷하게 밝혀지지 않은 낭종입니다.
            <br />
            보통 4cm 이상 일경우 난소 꼬임을 유발 할 수 있으며
            <br />
            주기적인 통증이 있을시 내원 하여야 합니다.
            <br />
            대부분 난소의 혹은 생명에 지장은 없지만 크기가 클 경우 수술 치료가 필요할 수 있습니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            난소혹 있을 때 흔하게
            <br />
            나타나는 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Components.Common.IndexLineContent
            data={{
              index: 0,
              content:
                ' 난소혹을 제때 치료하지 않고 방치하면 불임, 난임 등을 초래할 수 있습니다.\n아래와 같은 증상이 있을 경우 반드시 산부인과를 방문해 검사를 받아야 합니다.',
            }}
          />
          <Components.Common.Symptoms
            data={['생리주기 불규칙/무월경', '복부 팽만 및 불편감, 복통, 복부 압박 증상', '배변통 / 성교통 / 골반통 / 월경통', '소화불량']}
          />

          <Common.SizedBoxH height={70} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            난소혹 검사
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          {HowToCheckDatas.map((e, i) => (
            <>
              <Components.Common.ImageCard data={e} />
              <Common.SizedBoxH height={20} />
            </>
          ))}
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Disease3;
