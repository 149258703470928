import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './title.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { Colors } from 'common';

import AraClinicText from 'img/title/title_text.png';

import { HeaderMenuDatas, TitleDatas } from 'common/datas';

function Title(props: { menuIndex: number; subMenuIndex: number }): ReactElement {
  const { menuIndex, subMenuIndex } = props;

  return (
    <Styled.Container>
      <Styled.ImageContainer src={TitleDatas[menuIndex][subMenuIndex].image} />
      <Common.SizedBoxH height={407} />

      <Typo.Pretendard_Light fontSize={20} color={Colors.black}>
        {TitleDatas[menuIndex][subMenuIndex].headline}
      </Typo.Pretendard_Light>

      <Common.SizedBoxH height={12} />

      <Typo.Pretendard_ExtraBold fontSize={30} color={Colors.black}>
        {HeaderMenuDatas[menuIndex].sub![subMenuIndex].title}
      </Typo.Pretendard_ExtraBold>

      <Common.SizedBoxH height={14} />

      <Typo.Pretendard_Light textAlign="left" lineHeight={20} fontSize={15} color={Colors.gray300}>
        {TitleDatas[menuIndex][subMenuIndex].description}
      </Typo.Pretendard_Light>

      <Common.SizedBoxH height={8} />

      <Common.SizedImage src={AraClinicText} width={171} height={11} />
    </Styled.Container>
  );
}

export default Title;
