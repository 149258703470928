import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './pregnant2.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import InfertilityPhoto from 'img/photos/pregnant/2/infertility.png';

import CheckUpImage0 from 'img/photos/pregnant/2/test_0.png';
import CheckUpImage1 from 'img/photos/pregnant/2/test_1.png';

const CheckUpDatas = [
  {
    title: '호르몬 검사',
    description:
      '난소 기능과 배란에 관여하는 호르몬의 상태를 알기 위해 생리 2,3일에 시행하는 에스트로겐 난포 자극 호르몬 검사와 황체 형성 호르몬 검사를 할 수 있고 생리주기와는 상관없지만 AMH(항뮐러리안호르몬)검사를 포함하여 유즙 분비 호르몬, 갑상선 호르몬, 남성호르몬 등 기본적인 혈액검사를 시행합니다.',
    image: CheckUpImage0,
  },
  {
    title: '초음파',
    description:
      '가장 기본 검사로, 생리 시작일로부터 10일째 되는 날부터 2~3일 간격으로 배란일을 미리 예측할 수 있습니다. 이 외에도 자궁, 난소의 모양을 확인하고 난포의 성장 및 자궁내막의 상태, 배란시기를 관찰합니다. 정확한 배란일을 결정하여 부부관계시행함으로써 임신율을 높이는데 도움이 됩니다.',
    image: CheckUpImage1,
  },
];

function Pregnant2(): ReactElement {
  const menuIndex = 1;
  const subIndex = 2;

  const [checkUpIndex, setCheckUpIndex] = useState(0);

  return (
    <>
      <Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />

          <Common.SizedImage src={InfertilityPhoto} width={334} height={264} objectFit="cover" />

          <Common.TitleDescription>
            최근 난임 환자가 많이 늘어 나고 있습니다.
            <br />
            대부분의 경우는 원인으르 알 수 없는 경우가 많습니다.
            <br />
            정확한 진단을 위해서는 조기에 정확한 검사를 할 필요가 있습니다.
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            The cause of infertility
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            난임의 원인
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={26} />

          <Typo.Pretendard_Regular lineHeight={30} fontSize={15} color={Colors.gray200}>
            여성의 경우 배란장애, 난관문제, 자궁질환, 다낭성 난소증후군이 대표적이지만 결혼 연령의 상승, 높아진 출산연령, 환경 오염과
            현대사회의 스트레스도 배란장애를 일으키는 등 난임에 높은 영향을 끼치기도 합니다.
            <br />
            <br />
            최근에는 여성의 사회적 역활이 확장되면서 임신 시기가 점차 늦어지고 있는 것도 하나의 이유가 될 수 있으며 난소 기능의 저하와 함께
            조기 폐경을 경험하는 여성도 증가하고 있어 이 또한 원인이 될 수 있습니다. 남성의 경우 호르몬 이상, 선천적/후천적 무고환증,
            고환염, 선천적/후천적 무정자증 면역성 불임, 클라인펠터 증후군이 이유가 됩니다.
            <br />
            <br />
            정계정맥류, 성기능장애, 성기기형, 정관폐쇄는 물론 서구화된 식습관과 과한 음주와 흡연도 포함됩니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={100} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Type
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            난임 기본 검사
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={20} />

          <Typo.Pretendard_Regular fontSize={15} color={Colors.gray200}>
            난임 치료의 기본은 원인을 먼저 찾는 것입니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={20} />

          <Common.FlexRow width={210} justifyContent="space-between">
            {CheckUpDatas.map((e, i) => (
              <Styled.CheckUpButton onClick={() => setCheckUpIndex(i)} disabled={checkUpIndex === i} key={`button${i}`}>
                {e.title}
              </Styled.CheckUpButton>
            ))}
          </Common.FlexRow>

          <Common.SizedBoxH height={20} />

          <Common.SizedBox width={335} height={1}>
            <Common.Fill color={Colors.gold200} />
          </Common.SizedBox>

          <Common.SizedBoxH height={10} />

          <Typo.Pretendard_Regular textAlign="center" lineHeight={30} fontSize={15} color={Colors.gray200}>
            {CheckUpDatas[checkUpIndex].description}
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={10} />

          <Common.SizedBox width={335} height={1}>
            <Common.Fill color={Colors.gold200} />
          </Common.SizedBox>

          <Common.SizedBoxH height={50} />
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Pregnant2;
