import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import DescriptionImage from 'img/photos/disease/0/description.png';

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import CheckImage0 from 'img/photos/disease/0/check_0.png';
import CheckImage1 from 'img/photos/disease/0/check_1.png';
import CheckImage2 from 'img/photos/disease/0/check_2.png';

const HowToCheckDatas = [
  {
    title: '문진',
    description: '병력 청취 및 생활 습관 파악',
    image: CheckImage0,
  },
  {
    title: '원인 검사',
    description: '초음파 검사, 암 검진',
    image: CheckImage1,
  },
  {
    title: '각종 정밀 검사',
    description: '호르몬 검사 등',
    image: CheckImage2,
  },
];

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/0/treat_1.jpg';
import IndexLineContent from 'components/common/indexLineContent';
import IndexCard from 'components/common/indexCard';

const TreatmentDatas = [
  {
    title: '호르몬 요법',
    description: '월경 주기와 출혈량 조절을 위해 호르몬제, 호르몬 주사, 호르몬 분비 장치 등을 이용합니다.',
    image: TreatmentImage0,
  },
  {
    title: '수술 요법',
    description: '자궁내 소파술 및 고주파 기계를 이용하여 지혈을 통해 비정상 출혈을 조절 합니다.',
    image: TreatmentImage1,
  },
];

function Disease0(): ReactElement {
  const menuIndex = 2;
  const subIndex = 0;

  return (
    <>
      <Components.Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />

          <Common.SizedImage src={DescriptionImage} width={334} height={264} objectFit="cover" />

          <Common.TitleDescription>
            생리 기간과 상관 없이 출혈이 발생하는 경우를 말합니다.
            <br />
            보통 여성의 정상 월경 주기는 21~40일 정도입니다.
            <br />
            생리는 여성의 건강을 나타내는 지표이기 때문에 정상적인 주기를 벗어났다면 일시적인 경우를 제외하고는 시기를 놓치지 말고 산부인과
            전문의를 찾는 것이 좋습니다.
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            비정상출혈/생리불순원인
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={58} />

          <IndexLineContent
            data={{
              index: 0,
              title: '비정상출혈',
              content:
                ' 비정상출혈은 자궁근종, 난소종양, 자궁암, 난소암 등 종양 있는 경우나 경구피임 약 부작용, 자연유산, 자궁 외 임신, 극심한 스트레스, 체중변화 등 다양한 원인이 있어 현재 연령이나 신체 면역 상태 등을 종합적으로 고려하여 진단합니다.',
            }}
          />
          <Components.Common.Symptoms
            data={[
              '비정상출혈은 자궁근종, 난소종양, 자궁암, 난소암 등 종양 있는 경우나',
              '경구피임 약 부작용, 자연유산, 자궁 외 임신, 극심한 스트레스, 체중변화 등',
              '다양한 원인이 있어 현재 연령이나 신체 면역 상태 등을 종합적으로 고려하여 진단합니다.',
            ]}
          />

          <Common.SizedBoxH height={58} />

          <IndexLineContent
            data={{
              index: 1,
              title: '생리불순',
              content:
                '정상적인 생리주기는 20-90일 입니다. 생리불순은 영양섭취가 고르지 못한 경우, 내분비계의 이상, 극심한 스트레스, 체중변화 등 다양한 원인이 있으며, 가장 많은 원인이 배란 장애이므로 여성의 신체에 이상이 생겼다는 것을 의미합니다. 오랜기간 방치하면 골다공증, 우울증, 불임 등의 이차적인 질환을 초래해 치료가 중요합니다.',
            }}
          />
          <Components.Common.Symptoms
            data={[
              '불규칙한 생리 - 보름마다 생리를 할 경우',
              '세달이상 생리를 하지 않을 경우',
              '다양한 원인이 있어 현재 연령이나 신체 면역 상태 등을 종합적으로 고려하여 진단합니다.',
            ]}
          />

          <Common.SizedBoxH height={70} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            비정상출혈/생리불순
            <br />
            검사 방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          {HowToCheckDatas.map((e, i) => (
            <>
              <Components.Common.ImageCard data={{ ...e, content: e.description }} />
              <Common.SizedBoxH height={20} />
            </>
          ))}
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            비정상출혈/생리불순
            <br />
            치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {TreatmentDatas.map((e, i) => (
            <>
              <IndexCard data={{ ...e, index: i, indexString: 'Treat', content: e.description }} />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Disease0;
