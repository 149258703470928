import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/6/banner.png';

import TypeImage0 from 'img/photos/disease/7/typeImages/0.jpg';
import TypeImage1 from 'img/photos/disease/7/typeImages/1.jpg';
import TypeImage2 from 'img/photos/disease/7/typeImages/2.jpg';
import TypeImage3 from 'img/photos/disease/7/typeImages/3.jpg';
import TypeImage4 from 'img/photos/disease/7/typeImages/4.jpg';

import SymptomArrow from 'img/photos/disease/Arrow.svg';
import DownArrow from 'img/photos/disease/DownArrow.png';
import BackgroundImage from 'img/photos/disease/4/background.png';

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';
import TreatmentImage2 from 'img/photos/disease/4/treat_1.jpg';
import TreatmentImage3 from 'img/photos/disease/4/treat_1.jpg';

const TypeDatas = [
  {
    title: '세균성 질증',
    content:
      '세균성 질증은 어떤 원인으로 인해 질 내의 서식균이 세력을 읽고 전체 1% 미만으로 존재하던 혐기성 세균이 1000배로 증식하여 생기는 질염입니다. 세균성 질증이 있는 경우 골반염이 생길 확률이 높아지며, 후유증으로 나팔관이 막혀 불임이 되기도 합니다.',
    image: TypeImage0,
  },
  {
    title: '트리코모나스 질염',
    content:
      '트리코모나스는 기생충의 일종입니다.\n트리코모나스 질염은 성관계를 통해 전파되는 성병으로, 전파력이 매우 강해 감염된 파트너와 한 번만 성관계를 해도 70% 이상이 감염됩니다.\n트리코모나스가 방광으로 침입하면 방광염, 오줌소태를 유발하며 자궁내막을 타고 올라가 골반염을 일으키키도 합니다.',
    image: TypeImage1,
  },
  {
    title: '염증성 질염',
    content:
      '염증성 상피세포가 심하게 떨어져 나오면서 고름 같은 냉이 많이 생기는 경우를 말합니다.\n성교통, 외음부가 화끈거림, 냉의 양 증가 등의 증상을 유발합니다.',
    image: TypeImage2,
  },
  {
    title: '위축성 질염',
    content:
      '에스트로겐 결핍으로 질 벽이 얇아져 나타나는 질염입니다.\n 에스트로겐은 여성의 난소에서 주로 생성되기 때문에 난소 양쪽을 다 잘라내는 수술을 받았거나 폐경이후의 여성에게 나타납니다.\n서교통, 다량의 냉, 질 건조증 등의 증상을 유발합니다.',
    image: TypeImage3,
  },
  {
    title: '칸디다성 질염',
    content:
      '여성 전체의 75%가 살아가는 동안 단 한번 이상 겪는다고 하는 가장 흔한 형태의 질염입니다.\n45%의 여성이 1년에 2회 이상의 재발을 겪는다고 알려져 있습니다.\n흰색의 걸쭉한 냉과 심한 가려움증이 대표작인 증상입니다.',
    image: TypeImage4,
  },
];

const TreatmentDatas = [
  {
    title: '먹는 약',
    description: '',
    image: TreatmentImage0,
  },
  {
    title: '바르는 약',
    description: '',
    image: TreatmentImage1,
  },
  {
    title: '주사',
    description: '항생제 주사, 질 점막 주사, 영양 주사 등을 처방합니다.',
    image: TreatmentImage2,
  },
  {
    title: '질스케일링',
    description: '세균과 곰팡이가 번식하는 환경을 개선하여 재발을 예방합니다.',
    image: TreatmentImage3,
  },
];

function Disease7(): ReactElement {
  const menuIndex = 2;
  const subIndex = 7;

  return (
    <>
      <Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />
          <Common.SizedImage src={BannerImage} width={334} height={264} objectFit="cover" />

          <Common.TitleDescription>
            여성의 생식기인 질에 여러가지 원인으로 염증이 생기는 경우를 말합니다. 산부인과 질환 중에 가장 흔한 질환으로,
            <br />
            재발이 잦고 만성으로 이어지는 경우가 있어 적절한 치료가 필요합니다. 감추지 말고 치료하여 건강한 일상을 되찾으세요.
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Type
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            질염의 종류
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          {TypeDatas.map((e, i) => (
            <Components.Common.IndexLineContent data={{ ...e, index: i }} />
          ))}
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            질염 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Components.Common.IndexLineContent
            data={{
              index: 0,
              content:
                '질염을 제때 치료하지 않고 방치하면 방광염, 요도염, 골반염 등으로 진행될 수 있습니다.\n아래와 같은 증상이 있을 경우 반드시 산부인과를 방문해 검사를 받아야 합니다.',
            }}
          />

          <Components.Common.Symptoms data={['질 분비물 과다', '생선 냄새와 같은 악취', '참기 힘든 가려움', '배뇨통/성교통']} />
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundImage={BackgroundImage}>
          <Common.SizedBoxH height={40} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            질염 검사
          </Typo.Pretendard_Medium>
          <Common.SizedImage src={DownArrow} width={60} height={60} />
          <Common.SizedBoxH height={9} />
          <Typo.Pretendard_Regular fontSize={15} lineHeight={25} color={Colors.gray300}>
            질염은 질 분비물을 채취하여 PCR검사를 진행합니다.
            <br />
            1~2일 내에 검사 결과를 알 수 있으며, 균의 종류에 따라 추가 검사가 필요한 경우도 있습니다.
            <br />
            질염 검사는 매우 간다하며 통증이나 질 내 손상이 없습니다.
            <br />
            따라서 검사를 두려워 하지 마시고 내원하셔서 검사 받으시길 권합니다.
          </Typo.Pretendard_Regular>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            질염 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {TreatmentDatas.map((e, i) => (
            <>
              <Components.Common.IndexCard data={{ ...e, index: i, indexString: 'Treat', content: e.description }} />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Disease7;
