import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomBalloonImage from 'img/photos/disease/0/SymptomBalloon.svg';
import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';
import { vw } from 'common/utils';

export const ProgramCard = styled.div`
  width: ${vw(200)}px;
  height: ${vw(150)}px;

  border-radius: ${vw(10)}px;

  background: #ebe2cc;

  position: relative;
`;

export const ProgramCardIconContainer = styled.div`
  width: ${vw(80)}px;
  height: ${vw(80)}px;

  border-radius: 50%;

  background: #fff;
  box-shadow: 0px ${vw(4)}px ${vw(4)}px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  top: ${vw(-40)}px;
  left: calc((100% - ${vw(80)}px) / 2);
`;

export const ProgramCardViewport = styled.div`
  width: 100%;
  height: 100%;

  padding: ${vw(56)}px ${vw(20)}px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TestTypeContents = styled.div`
  width: ${vw(335)}px;
  height: ${vw(300)}px;

  border-radius: ${vw(10)}px;
  border: 1px solid var(--ebe-2-cc, #ebe2cc);

  display: grid;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(9, 1fr);

  padding: ${vw(12)}px ${vw(8)}px;
  grid-auto-flow: column;

  align-items: center;
`;
