import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './checkup0.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import Banner from 'img/photos/checkup/0/banner.png';

import ProgramIcon0 from 'img/photos/checkup/0/programIcons/0.png';
import ProgramIcon1 from 'img/photos/checkup/0/programIcons/1.png';
import ProgramIcon2 from 'img/photos/checkup/0/programIcons/2.png';
import ProgramIcon3 from 'img/photos/checkup/0/programIcons/3.png';

const ProgramCardDatas = [
  {
    icon: ProgramIcon0,
    content: '임신과 연관된 위험도 평가와 상담',
  },
  {
    icon: ProgramIcon1,
    content: '임신 전 검진',
  },
  {
    icon: ProgramIcon2,
    content: '엽산제의 적절한 공급',
  },
  {
    icon: ProgramIcon3,
    content: '예방접종',
  },
];

import ProgramImage0 from 'img/photos/checkup/0/programImages/0.jpg';
import ProgramImage1 from 'img/photos/checkup/0/programImages/1.jpg';
import ProgramImage2 from 'img/photos/checkup/0/programImages/2.png';

const ProgramStepDatas = [
  {
    title: '검사 전 상담',
    content:
      '가족력 조사와 각종 질병에 대한 상담과 불임,\n질염과 성병, 약물, 비만, 당뇨, 갑상선 질환 등등\n임신에 문제가 되거나, 임신 후 태아에게\n악영향을 줄 수 있는 문제에 대한 상담과\n평가를 먼저합니다.',
    image: ProgramImage0,
  },
  {
    title: '검사',
    content: '이러한 자료를 바탕으로 환자분 개개인에\n맞추어 필요한 예비부부 검사 프로그램을\n만들어 제시 해드립니다. ',
    image: ProgramImage1,
  },
  {
    title: '검사 후 결과상담',
    content:
      '가족력 조사와 각종 질병에 대한 상담과 불임,\n질염과 성병, 약물, 비만, 당뇨, 갑상선 질환 등등\n임신에 문제가 되거나, 임신 후 태아에게\n악영향을 줄 수 있는 문제에 대한 상담과\n평가를 먼저합니다.',
    image: ProgramImage2,
  },
];

import StepBarImage from 'img/photos/checkup/0/bar.png';

const CheckUpTypeDatas = [
  '혈당 검사',
  '갑상선 검사',
  '풍진항체 검사',
  '간염항체 검사 (A, B, C형)',
  '매독/에이즈 검사',
  '빈혈 검사',
  '혈액형 검사',
  '간기능 검사',
  '신장 검사',
  'VITD 검사',
  '소변 검사',
  '성병 검사 (STD)',
  '자궁경부세포검사',
  '인유두종바이러스검사',
  '난소나이검사 (AMH)',
  '여성호르몬 검사',
  '암 표지자 검사\n(CA 125, HE4)',
];

function CheckUp0(): ReactElement {
  const menuIndex = 3;
  const subIndex = 0;

  return (
    <>
      <Header />

      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />
          <Common.SizedImage src={Banner} width={334} height={264} objectFit="cover" />

          <Common.TitleDescription>
            미혼여성 혹은 결혼을 앞둔 여성을 위한 건강검진으로 여성자신의 건강과 임신에 영향을 줄 수 있는 위험요인을 찾아내고, 치료하여 미리
            예방하는 합니다. 대부분 표준화된 지침이 없이 임신 후에 시작하는 산전진찰 검사항목을 바탕으로 검사 프로그램을 운영하고 있습니다.{' '}
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            아라산부인과
            <br />
            예비부부검진 프로그램
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={60} />

          {ProgramCardDatas.map((e, i) => (
            <>
              <Styled.ProgramCard key={i}>
                <Styled.ProgramCardIconContainer>
                  <Common.SizedImage width={50} height={50} objectFit="contain" src={e.icon} />
                </Styled.ProgramCardIconContainer>
                <Styled.ProgramCardViewport>
                  <Typo.Pretendard_Regular fontSize={18} color={Colors.gray300} lineHeight={25}>
                    {e.content}
                  </Typo.Pretendard_Regular>
                </Styled.ProgramCardViewport>
              </Styled.ProgramCard>
              {i < ProgramCardDatas.length - 1 && <Common.SizedBoxH height={20} />}
            </>
          ))}
          <Common.SizedBoxH height={20} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Test
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            검사항목
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={20} />

          <Styled.TestTypeContents>
            {CheckUpTypeDatas.map((e) => (
              <Typo.Pretendard_Regular textAlign="left" color={Colors.gray200} fontSize={13}>
                · {e}
              </Typo.Pretendard_Regular>
            ))}
          </Styled.TestTypeContents>

          <Common.SizedBoxH height={40} />
        </Common.Contents>
      </Common.Container>

      <Footer />
    </>
  );
}

export default CheckUp0;
