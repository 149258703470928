import { Colors } from 'common';
import { vw } from 'common/utils';
import styled, { createGlobalStyle } from 'styled-components';

export const Header = styled.div`
  position: fixed;

  width: 100vw;
  height: ${vw(64)}px;

  top: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0 ${vw(15)}px 0 ${vw(19)}px;

  background-color: ${Colors.white};
  z-index: 100;
`;

export const FullMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding: ${vw(64)}px 0 0 0;
  background-color: ${Colors.white};

  display: flex;

  z-index: 50;
`;

export const FullMenuList = styled.div<{ backgroundColor: string }>`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${(p) => p.backgroundColor};
`;

export const FullMenuItem = styled.div`
  width: 100%;
  height: ${vw(40)}px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #f7f7f7;
  border-collapse: collapse;
`;
