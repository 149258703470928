import styled from 'styled-components';
import { Colors } from 'common';
import * as Typo from 'common/typography';

import { vw } from 'common/utils';

export const TitleContainer = styled.div`
  width: 100%;
  height: ${vw(241)}px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${Colors.white};
  background: linear-gradient(180deg, #ebe2cc 0%, #f1e4c4 29.69%, rgba(235, 226, 204, 0.47) 100%);

  background-size: 100% ${vw(398)}px;
`;
