import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';

import styled from 'styled-components';
import { Colors } from 'common';
import { vw } from 'common/utils';

const Container = styled.div`
  width: ${vw(334)}px;

  position: relative;

  border-radius: 0 ${vw(20)}px ${vw(20)}px ${vw(20)}px;
  background-color: ${Colors.white};

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const IconContainer = styled.div`
  width: ${vw(334)}px;
  height: ${vw(200)}px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  background: var(--radial, radial-gradient(50% 50% at 50% 50%, rgba(235, 226, 204, 0) 0%, #ebe2cc 100%));

  border-radius: 0 ${vw(20)}px 0 0;
`;

const TextContainer = styled.div`
  width: ${vw(334)}px;
  min-height: ${vw(110)}px;

  display: flex;
  flex-direction: column;

  padding: ${vw(20)}px;
`;

const IndexContainer = styled.div`
  width: ${vw(93)}px;
  height: ${vw(93)}px;

  border-radius: 50%;

  background: var(--665-e-4-b, #665e4b);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: ${vw(-49)}px;

  z-index: 1;
`;

const TitleText = styled(Typo.Pretendard_Medium)`
  font-size: ${vw(20)}px;
  text-align: left;
  color: ${Colors.gray300};

  mark {
    color: ${Colors.gold300};
    background-color: transparent;
  }
`;

function IndexCard(props: {
  data: {
    indexString: string;
    index: number;
    title: string;
    content: string;
    image: any;
    imageWidth?: number;
    imageHeight?: number;
  };
}): ReactElement {
  const { indexString, index, title, content, image, imageWidth, imageHeight } = props.data;
  return (
    <Container>
      <IndexContainer>
        <Typo.Pretendard_Light height={15} color={Colors.white} fontSize={20}>
          {indexString}
        </Typo.Pretendard_Light>
        <Typo.Pretendard_Regular height={40} color={Colors.white} fontSize={40}>
          0{index + 1}
        </Typo.Pretendard_Regular>
      </IndexContainer>

      <IconContainer>
        <Common.SizedImage width={imageWidth ?? 335} height={imageHeight ?? 200} src={image} />
      </IconContainer>

      <TextContainer>
        <TitleText>{title}</TitleText>
        <Common.SizedBoxH height={12} />
        <Typo.Pretendard_Regular textAlign="left" fontSize={15} color={Colors.gray200}>
          {content}
        </Typo.Pretendard_Regular>
      </TextContainer>
    </Container>
  );
}

export default IndexCard;
