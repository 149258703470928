import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/4/banner.png';
import BackgroundImage from 'img/photos/disease/4/background.png';
import CheckImage from 'img/photos/disease/4/check.png';

const CauseDatas = [
  '면역력이 저하된 경우',
  '소변을 장시간 참는 습관이 있는 경우',
  '용변을 뒤에서 앞으로 닦는 습관이 있는 경우',
  '잦은 성관계를 가진 경우',
];

import SymptomArrow from 'img/photos/disease/Arrow.svg';
import DownArrow from 'img/photos/disease/DownArrow.png';

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '약물 요법',
    description: '원인균을 제거하는 항생제 처방으로 증상을 호전 시킵니다.',
    image: TreatmentImage0,
  },
  {
    title: '주사 치료',
    description: '항생제를 주사합니다.',
    image: TreatmentImage1,
  },
];

function Disease4(): ReactElement {
  const menuIndex = 2;
  const subIndex = 4;

  return (
    <>
      <Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />

          <Common.SizedImage src={BannerImage} width={334} height={264} objectFit="cover" />
          <Common.TitleDescription>
            소변을 모아 놓는 방광에 염증이 생기는 질환입니다.
            <br />
            여성은 외요도구가 질과 항문 가까이에 위치하기 때문에 질 분비물이나 대변의 세균이 짧은 요도를 타고 방광으로 들어가 방광염을
            유발합니다.
            <br />
            원인을 정확히 파악하여 재발을 예방합니다.
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            방광염 원인
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Components.Common.PaperCheckList data={CauseDatas} />

          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            방광염 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Components.Common.IndexLineContent
            data={{
              index: 0,
              content:
                ' 방광염을 제때 치료하지 않고 방치하면 만성방광염, 신우신염 등으로 진행될 수 있습니다. 아래와 같은 증상이 있을 경우 반드시 산부인과를 방문해 검사를 받아야 합니다.',
            }}
          />
          <Components.Common.Symptoms data={['빈뇨', '잔뇨', '혈뇨', '배뇨통']} />
          <Common.SizedBoxH height={58} />
        </Common.Contents>

        <Common.Contents backgroundImage={BackgroundImage}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            아라산부인과 방광염검사
          </Typo.Pretendard_Medium>
          <Common.SizedImage src={DownArrow} width={60} height={60} />
          <Common.SizedBoxH height={9} />
          <Typo.Pretendard_Regular fontSize={15} lineHeight={25} color={Colors.gray300}>
            방광염은 원인균을 파악하는 소변검사를 진행하며
            <br />
            필요한 경우 세균배양검사를 진행하기도 합니다.
            <br />
            방광염은 치료는 잘 되지만 재발이 쉽다는 특성이 있어 정확한 원인 파악과
            <br />
            체계적인 치료 계획 수립이 무엇보다 중요합니다.
          </Typo.Pretendard_Regular>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            방광염 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {TreatmentDatas.map((e, i) => (
            <>
              <Components.Common.IndexCard data={{ ...e, index: i, indexString: 'Treat', content: e.description }} />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Disease4;
