import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './disease2.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/1/banner.png';

import CauseIcon0 from 'img/photos/disease/2/causeIcons/0.png';
import CauseIcon1 from 'img/photos/disease/2/causeIcons/1.png';
import CauseIcon2 from 'img/photos/disease/2/causeIcons/2.png';

const CauseDatas = [
  {
    title: '자궁 • 난소질환',
    contents: ['자궁내막증', '자궁내막, 경부 용종', '근종, 선근종', '자궁내막암, 경부암'],
    icon: CauseIcon0,
  },
  {
    title: '호르몬 이상',
    contents: ['조기폐경', '갑상선 질환', '고프로락틴혈증'],
    icon: CauseIcon1,
  },
  {
    title: '일시적 • 생리적 이상',
    contents: ['무배란', '스트레스', '약물'],
    icon: CauseIcon2,
  },
];

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import CheckImage0 from 'img/photos/disease/0/check_0.png';
import CheckImage1 from 'img/photos/disease/0/check_1.png';
import CheckImage2 from 'img/photos/disease/2/check_2.png';

const HowToCheckDatas = [
  {
    title: '문진',
    description: '병력 청취 및 생활 습관 파악',
    image: CheckImage0,
  },
  {
    title: '원인 검사',
    description: '초음파 검사, 호르몬 검사, 혈액 검사',
    image: CheckImage1,
  },
  {
    title: '임신검사',
    image: CheckImage2,
  },
];

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/0/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '호르몬 요법',
    description: '월경양을 조절하는 호르몬제, 호르몬 장치, 호르몬 주사 처방',
    image: TreatmentImage0,
  },
  {
    title: '원인 질환 치료',
    description: '비정상적인 월경양의 원인 질환을 찾아 치료 진행',
    image: TreatmentImage1,
  },
];

function Disease2(): ReactElement {
  const menuIndex = 2;
  const subIndex = 2;

  return (
    <>
      <Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />

          <Common.SizedImage src={BannerImage} width={334} height={264} objectFit="cover" />
          <Common.TitleDescription>
            평소보다 생리량이 너무 많은 경우 또는 생리량이 너무 적은 경우에 해당합니다.
            <br />
            일시적인 경우를 제외하고 생리량이 불규칙한 경우엔 반드시 내원하여 원인을 파악해야 합니다.
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            월경과다/월경과소의 원인
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {CauseDatas.map((e, i) => (
            <>
              <Styled.CauseCard key={e.title}>
                <Styled.CauseCardIconContainer>
                  <Common.SizedImage width={50} height={50} objectFit="contain" src={e.icon} />
                </Styled.CauseCardIconContainer>
                <Styled.CauseCardViewport>
                  <Common.SizedBoxH height={56} />
                  <Typo.Pretendard_Medium fontSize={20} color={Colors.gray300} lineHeight={26}>
                    {e.title}
                  </Typo.Pretendard_Medium>
                  <Common.SizedBoxH height={20} />
                  <Common.FlexRow>
                    <Common.SizedBoxW width={40} />
                    <Common.FlexColumn>
                      {e.contents.map((e, i) => (
                        <Typo.Pretendard_Regular textAlign="left" fontSize={15} height={25} color={Colors.gray300}>
                          &nbsp;&nbsp;• {e}
                        </Typo.Pretendard_Regular>
                      ))}
                    </Common.FlexColumn>
                  </Common.FlexRow>
                </Styled.CauseCardViewport>
              </Styled.CauseCard>
              <Common.SizedBoxH height={40} />
            </>
          ))}
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            월경과다/월경과소의 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Components.Common.IndexLineContent data={{ index: 0 }} />
          <Components.Common.Symptoms
            data={[
              '생리량이 150ml 이상일때 (오버나이트 가득 2-3시간에 하나 쓸경우)',
              '월경량이 너무 적다. (팬티라이너에 묻는 정도)',
              '일주일 넘계 월경이 지속된다.',
            ]}
          />

          <Common.SizedBoxH height={100} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            월경과다/월경과소
            <br />
            검사 방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          {HowToCheckDatas.map((e, i) => (
            <>
              <Components.Common.ImageCard data={{ ...e, content: e.description }} />
              <Common.SizedBoxH height={20} />
            </>
          ))}
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            월경과다/월경과소
            <br />
            치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {TreatmentDatas.map((e, i) => (
            <>
              <Components.Common.IndexCard data={{ ...e, index: i, indexString: 'Treat', content: e.description }} />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Disease2;
