import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './about3.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';

import { Colors } from 'common';
import { Container as MapDiv, NaverMap, Marker, useNavermaps } from 'react-naver-maps';
import { mapCenter } from 'common/datas';
import { Icons } from 'common/images';
import { vw } from 'common/utils';

function About3(): ReactElement {
  const menuIndex = 0;
  const subIndex = 3;

  const navermaps = useNavermaps();

  const mapStyle = {
    width: `${vw(334)}px`,
    height: `${vw(330)}px`,
    border: 'none',
  };

  return (
    <>
      <Header />

      <Common.Container>
        <Styled.TitleContainer>
          <Common.SizedBoxH height={80} />
          <Typo.Pretendard_ExtraBold fontSize={30} color={Colors.black}>
            진료시간/오시는길
          </Typo.Pretendard_ExtraBold>
          <Common.SizedBoxH height={4} />
          <Typo.Pretendard_Regular fontSize={15} color={Colors.gray200}>
            아라산부인과의원 진료시간과 오시는 길을 소개 합니다.
          </Typo.Pretendard_Regular>
        </Styled.TitleContainer>

        <Common.Contents>
          <Common.SizedBoxH height={40} />

          <MapDiv style={mapStyle}>
            <NaverMap defaultCenter={new navermaps.LatLng(mapCenter.lat, mapCenter.lng)} defaultZoom={15}>
              <Marker defaultPosition={new navermaps.LatLng(mapCenter.lat, mapCenter.lng)} />
            </NaverMap>
          </MapDiv>

          <Common.SizedBoxH height={40} />

          <Common.FlexColumn width={335} alignItems="stretch">
            <Common.FlexRow alignItems="center">
              <Common.SizedBoxW width={8} />
              <Common.SizedImage src={Icons.Ping} width={30} height={30} />
              <Common.SizedBoxW width={10} />
              <Typo.Pretendard_Medium fontSize={30} color={Colors.gray300}>
                오시는 길
              </Typo.Pretendard_Medium>
            </Common.FlexRow>
            <Common.SizedBoxH height={10} />
            <Common.SizedBox width={335} height={1}>
              <Common.Fill color="#815233" />
            </Common.SizedBox>
            <Common.SizedBoxH height={20} />
            <Typo.Pretendard_Regular textAlign="left" color={Colors.gray200} fontSize={15} lineHeight={25}>
              인천 서구 이음대로 392,
              <br />
              5층 검단아라산부인과의원
              <br />
              (스타벅스 검단신도시점 건물)
            </Typo.Pretendard_Regular>
          </Common.FlexColumn>

          <Common.SizedBoxH height={44} />

          <Common.FlexColumn width={335} alignItems="stretch">
            <Common.FlexRow alignItems="center">
              <Common.SizedBoxW width={8} />
              <Common.SizedImage src={Icons.Clock} width={30} height={30} />
              <Common.SizedBoxW width={10} />
              <Typo.Pretendard_Medium fontSize={30} color={Colors.gray300}>
                진료시간
              </Typo.Pretendard_Medium>
            </Common.FlexRow>
            <Common.SizedBoxH height={10} />
            <Common.SizedBox width={335} height={1}>
              <Common.Fill color="#815233" />
            </Common.SizedBox>
            <Common.SizedBoxH height={20} />
            <Common.FlexRow width={335} justifyContent="space-between">
              <Typo.Pretendard_Regular width={156} textAlign="left" color={Colors.gray200} fontSize={15} lineHeight={25}>
                평일
                <br />
                토요일
                <br />
                <br />
                일요일&공휴일 휴진
              </Typo.Pretendard_Regular>
              <Typo.Pretendard_Regular textAlign="left" color={Colors.gray200} fontSize={15} lineHeight={25}>
                AM 09:00 - PM 08:00 <br />
                AM 09:00 - PM 04:00 <br />
                (점심시간 없이 진료)
              </Typo.Pretendard_Regular>
            </Common.FlexRow>
          </Common.FlexColumn>

          <Common.SizedBoxH height={44} />

          <Common.FlexColumn width={335} alignItems="stretch">
            <Common.FlexRow alignItems="center">
              <Common.SizedBoxW width={8} />
              <Common.SizedImage src={Icons.Phone} width={30} height={30} />
              <Common.SizedBoxW width={10} />
              <Typo.Pretendard_Medium fontSize={30} color={Colors.gray300}>
                상담 및 예약
              </Typo.Pretendard_Medium>
            </Common.FlexRow>
            <Common.SizedBoxH height={10} />
            <Common.SizedBox width={335} height={1}>
              <Common.Fill color="#815233" />
            </Common.SizedBox>
            <Common.SizedBoxH height={17} />
            <Typo.BodoniMT_BoldItalic fontSize={48} color={Colors.gray300} textAlign="left">
              032 - 569 - 7575
            </Typo.BodoniMT_BoldItalic>
          </Common.FlexColumn>

          <Common.SizedBoxH height={80} />
        </Common.Contents>
      </Common.Container>

      <Footer />
    </>
  );
}

export default About3;
