import { Colors } from 'common';
import styled, { createGlobalStyle } from 'styled-components';

import ServiceBackgroundImage from 'img/main/service_background.png';
import { vw } from 'common/utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  white-space: pre-line;
  position: relative;

  padding-top: ${vw(64)}px;
`;

export const MainViewport = styled.div`
  width: 100%;
  height: calc(100vh - ${vw(64)}px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

export const MainVideoTextArea = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Solutions = styled.div`
  width: 100%;

  padding: 0 ${vw(20)}px;

  background-color: ${Colors.white};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const SloutionCardContainer = styled.div`
  width: 100%;
  height: ${vw(292)}px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SolutionCard = styled.div`
  width: ${vw(161)}px;
  height: ${vw(292)}px;
  border-radius: ${vw(20)}px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`;

export const SolutionCardDescription = styled.div`
  width: 100%;
  height: ${vw(105)}px;

  padding: ${vw(14)}px ${vw(11)}px 0;

  background-color: ${Colors.gold100};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Services = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 ${vw(20)}px;

  background-image: url(${ServiceBackgroundImage});
  background-size: cover;
`;

export const ServiceCard = styled.div`
  width: ${vw(333)}px;
  height: ${vw(190)}px;

  background: rgba(0, 0, 0, 0.5);
  border-radius: ${vw(16)}px 0px;

  padding: ${vw(29)}px ${vw(17)}px 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 1000;
`;
