import { Colors } from 'common';
import { vw } from 'common/utils';
import styled, { createGlobalStyle } from 'styled-components';

export const Footer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: ${Colors.gold200};

  padding: 0 ${vw(20)}px;
`;

export const FooterLogo = styled.img`
  width: ${vw(262)}px;
  height: ${vw(49)}px;
  mix-blend-mode: overlay;
`;
