import { Colors } from 'common';
import * as Typo from 'common/typography';
import styled, { createGlobalStyle } from 'styled-components';

import SymptomBalloonImage from 'img/photos/disease/0/SymptomBalloon.svg';
import SymptomContainerImage from 'img/photos/disease/0/SymptomContainer.png';
import { vw } from 'common/utils';

export const TestTypeContents = styled.div`
  width: ${vw(335)}px;
  height: ${vw(130)}px;

  border-radius: ${vw(10)}px;
  border: 1px solid var(--ebe-2-cc, #ebe2cc);

  display: grid;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);

  padding: ${vw(12)}px ${vw(8)}px;
  grid-auto-flow: column;

  align-items: center;
`;
