import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/6/banner.png';
import CheckImage from 'img/photos/disease/4/check.png';

const CauseDatas = [
  '비만(체지방증가)으로 인한 경우',
  '인슐린저항성으로 인한 경우',
  '남성 호르몬이 과다하게 나오는 경우',
  '유전으로 인한 경우',
];

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import CheckImage0 from 'img/photos/disease/0/check_0.png';
import CheckImage1 from 'img/photos/disease/0/check_1.png';
import CheckImage2 from 'img/photos/disease/2/check_2.png';

const CheckUpDatas = [
  {
    title: '초음파 검사',
    image: CheckImage0,
  },
  {
    title: '혈액 검사',
    image: CheckImage1,
  },
  {
    title: '호르몬 검사',
    image: CheckImage2,
  },
];

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '호르몬 요법',
    description: '남성 호르몬 분비를 줄이는 피임약, 호르몬제 복용',
    image: TreatmentImage0,
  },
  {
    title: '체중 조절',
    description: '체중 조절을 통해 발병 가능성을 낮춤',
    image: TreatmentImage1,
  },
];

function Disease6(): ReactElement {
  const menuIndex = 2;
  const subIndex = 6;

  return (
    <>
      <Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />
          <Common.SizedImage src={BannerImage} width={334} height={264} objectFit="cover" />
          <Common.TitleDescription>
            한달에 한 개씩 배란되어야 하는 난포가 정상적으로 배란되지 못하고 쌓여 정상 범위보다 많이 존재하게 되는 질환입니다.
            <br />
            뚜렷한 원인이 밝혀지지는 않았으나 무월경, 난임등 다양한 이상한 증상을 유발해 치료가 필요합니다.
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            다낭성난소증후군 원인
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Typo.Pretendard_Regular fontSize={15} lineHeight={25} color={Colors.gray200}>
            다낭성난소증후군의 원인은 명확하게 밝혀진 바는 없으나, 대체적으로 아래와 같은 이유로 발병한다고 보고 있습니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={40} />

          <Components.Common.PaperCheckList data={CauseDatas} />

          <Common.SizedBoxH height={69} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            다낭성난소증후군 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Components.Common.IndexLineContent
            data={{
              index: 0,
              content:
                '다낭성난소증후군을 제때 치료하지 않고 방치하면 불임, 난임 등을 초래할 수 있습니다. 아래와 같은 증상이 있을 경우 반드시 산부인과를 방문해 검사를 받아야 합니다.',
            }}
          />

          <Components.Common.Symptoms
            data={['생리주기 불규칙/무월경', '얼굴과 몸에 여드름이 많이 난다.', '갑자기 체중이 많이 증가했다.', '몸에 털이 많이 난다.']}
          />

          <Common.SizedBoxH height={70} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            다낭성난소증후군 검사
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          {CheckUpDatas.map((e, i) => (
            <>
              <Components.Common.ImageCard data={e} />
              <Common.SizedBoxH height={20} />
            </>
          ))}
          <Common.SizedBoxH height={20} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            다낭성난소증후군 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {TreatmentDatas.map((e, i) => (
            <>
              <Components.Common.IndexCard data={{ ...e, index: i, indexString: 'Treat', content: e.description }} />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Disease6;
