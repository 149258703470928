import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './about1.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';
import { Interiors } from 'common/datas';
import { Icons } from 'common/images';

function About1(): ReactElement {
  const menuIndex = 0;
  const subIndex = 1;

  const [index, setIndex] = useState(0);

  const onPrev = () => {
    setIndex((index) => {
      index--;
      if (index < 0) index = Interiors.length - 1;
      return index;
    });
  };

  const onNext = () => {
    setIndex((index) => {
      index++;
      if (index >= Interiors.length) index = 0;
      return index;
    });
  };

  return (
    <>
      <Header />

      <Common.Container>
        <Styled.TitleContainer>
          <Common.SizedBoxH height={80} />
          <Typo.Pretendard_ExtraBold fontSize={30} color={Colors.black}>
            병원 둘러보기
          </Typo.Pretendard_ExtraBold>
          <Common.SizedBoxH height={4} />
          <Typo.Pretendard_Regular fontSize={15} color={Colors.gray200}>
            루아산부인과의원 내부를 미리 볼수 있습니다.
          </Typo.Pretendard_Regular>
        </Styled.TitleContainer>

        <Common.Contents>
          <Common.SizedBoxH height={40} />

          <Styled.PreviewDiv>
            <Common.SizedImage src={Interiors[index].image} width={334} height={330} objectFit="cover" />
            <Styled.PreviewPrevBtn onClick={onPrev}>
              <Common.SizedImage src={Icons.ArrowLeft} width={24} height={46} />
            </Styled.PreviewPrevBtn>
            <Styled.PreviewNextBtn onClick={onNext}>
              <Common.SizedImage src={Icons.ArrowRight} width={24} height={46} />
            </Styled.PreviewNextBtn>
            <Styled.PreviewName>{Interiors[index].name}</Styled.PreviewName>
          </Styled.PreviewDiv>

          <Common.SizedBoxH height={20} />

          <Common.FlexRow width={335} alignItems="center" justifyContent="space-between">
            {Interiors.slice(0, 4).map((e, i) => (
              <Styled.PhotoBtn key={i} disabled={index === i} onClick={(e) => setIndex(i)}>
                <img src={e.image} />
              </Styled.PhotoBtn>
            ))}
          </Common.FlexRow>
          <Common.SizedBoxH height={10} />
          <Common.FlexRow width={335} alignItems="center" justifyContent="space-between">
            {Interiors.slice(4, 8).map((e, i) => (
              <Styled.PhotoBtn key={i} disabled={index === i + 4} onClick={(e) => setIndex(i + 4)}>
                <img src={e.image} />
              </Styled.PhotoBtn>
            ))}
          </Common.FlexRow>

          <Common.SizedBoxH height={40} />
        </Common.Contents>
      </Common.Container>

      <Footer />
    </>
  );
}

export default About1;
