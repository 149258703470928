import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';

import styled from 'styled-components';
import { Colors } from 'common';
import { vw } from 'common/utils';

const Container = styled.div<{ backgroundImage: '*png' }>`
  width: ${vw(275)}px;
  height: ${vw(275)}px;

  padding: ${vw(7)}px ${vw(14)}px;

  background-image: url(${(p) => p.backgroundImage});

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

function ImageCard(props: {
  data: {
    image: any;
    title?: string;
    content?: string;
  };
}): ReactElement {
  const { image, title, content } = props.data;
  return (
    <Container backgroundImage={image}>
      {title && (
        <>
          <Typo.Pretendard_Medium fontSize={25} color={Colors.white}>
            {title}
          </Typo.Pretendard_Medium>
          <Common.SizedBoxH height={7} />
        </>
      )}

      {content && (
        <>
          <Typo.Pretendard_Regular fontSize={17} color={Colors.white}>
            {content}
          </Typo.Pretendard_Regular>
          <Common.SizedBoxH height={7} />
        </>
      )}
    </Container>
  );
}

export default ImageCard;
