import { Colors } from 'common';
import * as Typo from 'common/typography';
import { vw } from 'common/utils';
import styled, { createGlobalStyle } from 'styled-components';

export const MarkText = styled(Typo.Pretendard_Regular)`
  mark {
    color: ${Colors.gold300};
    background-color: transparent;
    font-weight: 600;
  }
`;

export const Percent = styled.div`
  width: ${vw(104)}px;
  height: ${vw(105)}px;

  position: relative;
`;

export const PercentFrame = styled.img`
  width: ${vw(104)}px;
  height: ${vw(105)}px;

  position: absolute;
  top: 0;
  left: 0;
`;

export const PercentViewport = styled.div`
  position: absolute;

  width: ${vw(104)}px;
  height: ${vw(105)}px;

  padding: ${vw(11)}px 0 0 ${vw(6)}px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PercentText = styled(Typo.Pretendard_ExtraBold)`
  vertical-align: bottom;

  mark {
    background-color: transparent;
    font-weight: 400;
    font-size: ${vw(20)}px;
  }
`;

export const CheckUpTitle = styled.div<{ color: string }>`
  width: ${vw(104)}px;
  height: ${vw(29)}px;
  border-radius: ${vw(25)}px;
  background-color: ${(p) => p.color};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RecommandBox = styled.div`
  width: ${vw(60)}px;
  height: ${vw(25)}px;
  border: 1px solid var(--ce-9715, #ce9715);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CautionBox = styled.div`
  width: ${vw(335)}px;

  border: 1px solid ${Colors.gold200};

  display: flex;
  flex-direction: column;

  padding: ${vw(31)}px ${vw(21)}px ${vw(26)}px ${vw(31)}px;
`;
