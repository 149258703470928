import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/8/banner.png';
import StepImage from 'img/photos/disease/8/step_image.png';

import SymptomArrow from 'img/photos/disease/Arrow.svg';
import DownArrow from 'img/photos/disease/DownArrow.png';
import BackgroundImage from 'img/photos/disease/4/background.png';

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/4/treat_1.jpg';
import TreatmentImage2 from 'img/photos/disease/4/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '인유두종 바이러스',
    description:
      '자궁경부암은 성관계에 의한 인유두종 바이러스 감염이 가장 큰 원인으로 성관계 파트너가 많거나 성관계를 일찍 시작하였을 경우 발병 가능성이 높아집니다.',
    image: TreatmentImage0,
  },
  {
    title: '예방접종',
    description:
      '가다실 4가, 9가 예방 접종을 통해서 자궁경부암을 미리 예방 할 수 있습니다.\n국가에서 청소년 대상으로 가다실 4가를 무료 예방접종을 지원합니다.',
    image: TreatmentImage1,
  },
  {
    title: '몸의 이상 변화 확인',
    description:
      '성관계 뒤 출혈, 폐경 여성의 출혈, 질 분비물 증가 및 악취 등 몸에 나타나는 이상 변화가 나타나면 방치하지 말고 산부인과에 내원하여 검사를 받습니다.',
    image: TreatmentImage2,
  },
];

function Disease8(): ReactElement {
  const menuIndex = 2;
  const subIndex = 8;

  return (
    <>
      <Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />

          <Common.SizedImage src={BannerImage} width={334} height={264} objectFit="cover" />
          <Common.TitleDescription>
            자궁 경부에 암이 발생한 경우를 말합니다.
            <br />
            발생원인 대부분이 성관계를 통한 인두유종 바이러스 감염입니다. 인두유종 바이러스에 감염되었을 경우 특별한 증상이 없고, 시간이
            지나면 자연적으로 소실되는데 극히 소수에서만 이 바이러스가 상피 세포를 변형시켜 암으로 발전하게 됩니다.{' '}
          </Common.TitleDescription>

          <Common.SizedBoxH height={44} />

          <Typo.Pretendard_Regular fontSize={15} lineHeight={25} color={Colors.black}>
            자궁경부암은 암이 되기 이전 단계인 전암단계를 상당 기간 거치는 것이 특징입니다.
            <br />
            이형성증 {'>'} 상피내암 {'>'} 자궁경부암 순서 로 평균 7~10년에 걸쳐 진행됩니다.
            <br />
            단계가 높아질 수록 자궁경부암으로 발전될 가능성이 높아 평소 정기 검진이 필수적입니다.
          </Typo.Pretendard_Regular>

          <Common.SizedBoxH height={80} />

          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Symptom
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            자궁경부암 증상
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          <Components.Common.IndexLineContent
            data={{
              index: 0,
              content:
                '자궁경부암은 초기에는 증상이 거의 없으며, 어느 정도 진행이 된 후 증상이 발생합니다. 아래와 같은 증상이 있을 경우 반드시 산부인과를 방문해 검사를 받아야 합니다.',
            }}
          />

          <Components.Common.Symptoms data={['질 분비물 과다 및 악취', '배뇨 장애', '체중 감소', '성관계 후 출혈']} />
          <Common.SizedBoxH height={70} />
        </Common.Contents>

        <Common.Contents backgroundImage={BackgroundImage}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Check up
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            아라산부인과
            <br />
            자궁경부암 검사
          </Typo.Pretendard_Medium>
          <Common.SizedImage src={DownArrow} width={60} height={60} />
          <Common.SizedBoxH height={9} />
          <Typo.Pretendard_Regular fontSize={15} lineHeight={25} color={Colors.gray300}>
            자궁경부암은 자궁 경부의 세포를 면봉이나 칫솔 모양의 특수 기구로 살짝 긁어내는 액상 세포 검사를 진행합니다.
            <br />
            약 2분 이내면 완료되는 간단한 검사로 통증이나 질 내 손상이 없습니다.
            <br />
            성경험이 있는 만 20세 여성의 경우 국민건강보험에 가입되었다면 누구나 2년에 한 번씩 무료 자궁경부암 검진이 가능합니다.
          </Typo.Pretendard_Regular>
          <Common.SizedBoxH height={40} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Precaution
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            자궁경부암 예방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {TreatmentDatas.map((e, i) => (
            <>
              <Components.Common.IndexCard data={{ ...e, index: i, indexString: 'Method', content: e.description }} />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Disease8;
