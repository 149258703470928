import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import * as Components from 'components';
import Header from 'components/header';
import Footer from 'components/footer';
import Title from 'components/title';
import { Colors } from 'common';

import BannerImage from 'img/photos/disease/1/banner.png';

import SymptomArrow from 'img/photos/disease/Arrow.svg';

import CheckImage0 from 'img/photos/disease/0/check_0.png';
import CheckImage1 from 'img/photos/disease/0/check_1.png';
import CheckImage2 from 'img/photos/disease/0/check_2.png';

const HowToCheckDatas = [
  {
    title: '문진',
    description: '병력 청취 및 생활 습관 파악',
    image: CheckImage0,
  },
  {
    title: '원인 검사',
    description: '초음파 검사, 암 검진',
    image: CheckImage1,
  },
];

import TreatmentImage0 from 'img/photos/disease/0/treat_0.jpg';
import TreatmentImage1 from 'img/photos/disease/0/treat_1.jpg';

const TreatmentDatas = [
  {
    title: '약물 요법',
    description: '배란을 조절하는 호르몬제, 통증을 줄여주는 진통제 처방',
    image: TreatmentImage0,
  },
  {
    title: '원인 질환 치료',
    description: '통증을 유발하는 원인 질환을 찾아 치료 진행',
    image: TreatmentImage1,
  },
];

function Disease1(): ReactElement {
  const menuIndex = 2;
  const subIndex = 1;

  return (
    <>
      <Header />
      <Common.Container>
        <Title menuIndex={menuIndex} subMenuIndex={subIndex} />

        <Common.Contents>
          <Common.SizedBoxH height={43} />
          <Common.SizedImage src={BannerImage} width={334} height={264} objectFit="cover" />
          <Common.TitleDescription>
            월경기간 전후로 나타나는 주기적인 통증입니다.
            <br />
            특별한 질환이나 원인 없이 호르몬 과다 분비로 인해 나타나는 경우가 많고, 통증이 극심할 경우 일상생활에도 큰 영향을 끼치게 됩니다.
            <br />
            통증이 심하다면 아라산부인과와 삼담을 받아보세요.
          </Common.TitleDescription>
          <Common.SizedBoxH height={44} />
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Type
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            월경통의 종류
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={58} />

          <Components.Common.IndexLineContent
            data={{
              index: 0,
              title: '일차성(원발성) 월경통',
              content:
                ' 병적 요인이 없는 생리통으로 보통 초경 1~2년 사이에 일반적으로 발생합니다. 생리 때 자궁에서 나오는 프로스타글라딘이 자궁 근육의 긴장도를 높여 강한 수축이 이뤄지면서 통증이 발생합니다. 주로 젊은 여성에게 발생하며 생리 시작 직전 혹은 직후에 시작해 2~3일 이내에 증상이 사라지는 특징이 있습니다.',
            }}
          />
          <Components.Common.Symptoms
            data={['자궁 수축과 혈류감소로 발생', '생리시작 수시간 전 또는 직전에 발생되어 2~3일간 지속', '심한 경우 구토, 설사를 동반']}
          />

          <Common.SizedBoxH height={58} />

          <Components.Common.IndexLineContent
            data={{
              index: 1,
              title: '이차성(속발성) 월경통',
              content:
                ' 자궁근종, 자궁내막증, 자궁선근증 등 자궁 내 병적 요인이 생겨 발생하는데, 생리 시작 전 1~2주부터 나타나며 생리가 끝난 후에도 며칠간 통증이 지속합니다. 주로 초경 4년 이후에 발생합니다.',
            }}
          />
          <Components.Common.Symptoms data={['골반에 병변이 있어 생기는 생리통으로 원인으로는 자궁내막증, 자궁선근증, 자궁근종']} />

          <Common.SizedBoxH height={70} />
        </Common.Contents>

        <Common.Contents>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Cause
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            월경통/배란통 검사 방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={40} />

          {HowToCheckDatas.map((e, i) => (
            <>
              <Components.Common.ImageCard data={{ ...e, content: e.description }} />
              <Common.SizedBoxH height={20} />
            </>
          ))}
        </Common.Contents>

        <Common.Contents backgroundColor={Colors.gold100}>
          <Common.SizedBoxH height={40} />
          <Typo.Pretendard_Medium fontSize={10} color={'#dddddd'}>
            Treatment
          </Typo.Pretendard_Medium>
          <Typo.Pretendard_Medium fontSize={30} color={Colors.black}>
            월경통/배란통 치료방법
          </Typo.Pretendard_Medium>

          <Common.SizedBoxH height={70} />

          {TreatmentDatas.map((e, i) => (
            <>
              <Components.Common.IndexCard data={{ ...e, index: i, indexString: 'Treat', content: e.description }} />
              <Common.SizedBoxH height={60} />
            </>
          ))}
        </Common.Contents>
      </Common.Container>
      <Footer />
    </>
  );
}

export default Disease1;
