import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import * as Styled from './header.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { CommonImages, Icons } from 'common/images';
import { Link } from 'react-router-dom';
import { Colors } from 'common';
import { HeaderMenuDatas } from 'common/datas';

function Header(props?: { menuIndex?: number; subMenuIndex?: number }): ReactElement {
  const [showFullMenu, setShowFullMenu] = useState(false);

  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [showSelectSubMenu, setShowSelectSubMenu] = useState(false);

  return (
    <>
      <Styled.Header>
        {showFullMenu ? (
          <Common.NoOpacityButton onClick={() => setShowFullMenu(false)}>
            <Common.SizedImage src={Icons.Close} width={30} height={24} />
          </Common.NoOpacityButton>
        ) : (
          <Common.NoOpacityButton onClick={() => setShowFullMenu(true)}>
            <Common.SizedImage src={Icons.Menu} width={30} height={24} />
          </Common.NoOpacityButton>
        )}

        <Link to="/">
          <Common.SizedImage src={CommonImages.Logo} width={80} height={43} objectFit="contain" />
        </Link>
        <a href="tel:032-569-7575">
          <Common.SizedImage src={Icons.Phone} width={35} height={35} />
        </a>
      </Styled.Header>

      {showFullMenu && (
        <Styled.FullMenu>
          <Styled.FullMenuList backgroundColor={Colors.gold100}>
            {HeaderMenuDatas.map((e, i) => (
              <Styled.FullMenuItem>
                <Common.NoOpacityButton onClick={() => setSelectedMenuIndex(i)}>
                  <Typo.Pretendard_Light fontSize={12} color={Colors.black}>
                    {e.title}
                  </Typo.Pretendard_Light>
                </Common.NoOpacityButton>
              </Styled.FullMenuItem>
            ))}
          </Styled.FullMenuList>
          <Styled.FullMenuList backgroundColor={Colors.white}>
            {HeaderMenuDatas[selectedMenuIndex].sub?.map((e, i) => (
              <Styled.FullMenuItem>
                <Link to={`${HeaderMenuDatas[selectedMenuIndex].path}${e.path}`}>
                  <Typo.Pretendard_Light fontSize={12} color={Colors.black}>
                    {e.title}
                  </Typo.Pretendard_Light>
                </Link>
              </Styled.FullMenuItem>
            ))}
          </Styled.FullMenuList>
        </Styled.FullMenu>
      )}
    </>
  );
}

export default Header;
