import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import * as Styled from './footer.style';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { CommonImages } from 'common/images';
import { Link } from 'react-router-dom';
import { Colors } from 'common';

import { Container as MapDiv, NaverMap, Marker, useNavermaps } from 'react-naver-maps';
import { mapCenter } from 'common/datas';
import { vw } from 'common/utils';

import FooterLogoImage from 'img/common/Logo_new.png';

function Footer(): ReactElement {
  const navermaps = useNavermaps();

  const mapStyle = {
    width: `${vw(332)}px`,
    height: `${vw(300)}px`,
    border: 'none',
  };

  return (
    <Styled.Footer>
      <Common.SizedBoxH height={42} />
      <Typo.BodoniModa_Italic textAlign="left" fontWeight={600} fontSize={20} color={Colors.gray300}>
        Contact
      </Typo.BodoniModa_Italic>

      <Common.SizedBox width={335} height={1}>
        <Common.Fill color={Colors.gray300} />
      </Common.SizedBox>

      <Common.SizedBoxH height={9} />

      <Typo.BodoniMT_BoldItalic textAlign="left" fontSize={30} color={Colors.gray300}>
        032 - 569 - 7575
      </Typo.BodoniMT_BoldItalic>

      <Common.SizedBoxH height={26} />

      <Typo.BodoniModa_Italic textAlign="left" fontWeight={600} fontSize={20} color={Colors.gray300}>
        Treatment Hours
      </Typo.BodoniModa_Italic>

      <Common.SizedBox width={335} height={1}>
        <Common.Fill color={Colors.gray300} />
      </Common.SizedBox>

      <Common.SizedBoxH height={22} />

      <Common.FlexRow width={335} alignItems="flex-start" justifyContent="space-between">
        <Typo.Pretendard_Regular textAlign="left" lineHeight={30} fontSize={12} color={Colors.gray300}>
          평일
          <br />
          토요일
          <br />
          <br />
          일요일&공휴일 휴진
        </Typo.Pretendard_Regular>

        <Typo.Pretendard_Regular textAlign="left" lineHeight={30} fontSize={12} color={Colors.gray300}>
          AM 09:00 - PM 08:00 <br />
          AM 09:00 - PM 04:00 <br />
          (점심시간 없이 진료)
        </Typo.Pretendard_Regular>
      </Common.FlexRow>
      <Common.SizedBoxH height={15} />

      <Typo.BodoniModa_Italic textAlign="left" fontWeight={600} fontSize={20} color={Colors.gray300}>
        Location
      </Typo.BodoniModa_Italic>

      <Common.SizedBox width={335} height={1}>
        <Common.Fill color={Colors.gray300} />
      </Common.SizedBox>

      <Common.SizedBoxH height={12} />

      <Typo.Pretendard_Regular textAlign="left" fontSize={12} color={Colors.gray300}>
        인천 서구 이음대로 392, 5층 검단아라산부인과의원 (스타벅스 검단신도시점 건물)
      </Typo.Pretendard_Regular>

      <Common.SizedBoxH height={9} />

      <MapDiv style={mapStyle}>
        <NaverMap defaultCenter={new navermaps.LatLng(mapCenter.lat, mapCenter.lng)} defaultZoom={15}>
          <Marker defaultPosition={new navermaps.LatLng(mapCenter.lat, mapCenter.lng)} />
        </NaverMap>
      </MapDiv>

      <Common.SizedBoxH height={42} />

      <Styled.FooterLogo src={FooterLogoImage} />
      <Common.SizedBoxH height={42} />

      <Common.FlexRow>
        <Link to="/uninsured">
          <Typo.Pretendard_Regular textAlign="left" lineHeight={21} fontSize={12} color={Colors.gray300}>
            비급여수가안내
          </Typo.Pretendard_Regular>
        </Link>
        <Typo.Pretendard_Regular textAlign="left" lineHeight={21} fontSize={12} color={Colors.gray300}>
          &nbsp;l&nbsp;
        </Typo.Pretendard_Regular>
        <Link to="/rights">
          <Typo.Pretendard_Regular textAlign="left" lineHeight={21} fontSize={12} color={Colors.gray300}>
            환자권리장전
          </Typo.Pretendard_Regular>
        </Link>
      </Common.FlexRow>

      <Common.SizedBoxH height={14} />

      <Typo.Pretendard_Regular textAlign="left" fontSize={10} color={Colors.gray300}>
        상호명 : 검단아라산부인과 사업자등록번호 : 319-98-01502 대표자 : 남선미
        <br />
        인천 서구 이음대로 392, 5층 검단아라산부인과의원 (스타벅스 검단신도시점 건물)
        <br />
        대표전화 : 032) 569-7575 고객센터 이메일 : nsm1105@naver.com
        <br />
        <br />
        Copyright © 2023. 검단아라산부인과. All rights reserved.
      </Typo.Pretendard_Regular>

      <Common.SizedBoxH height={33} />
    </Styled.Footer>
  );
}

export default Footer;
